import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa"; // Importing an icon
import "../NotFound/NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Takes the user to the previous page
  };

  return (
    <div className="not-found-container">
      {/* Back Button */}
      <div className="back-button-container">
        <button
          onClick={handleGoBack}
          className="rounded bg-[#001227] text-white font-semibold px-4 py-2 flex items-center gap-2"
        >
          <FaArrowLeft /> Back
        </button>
      </div>

      {/* 404 Message */}
      <div className="not-found-content">
        <h1>404</h1>
        <p>NOT FOUND</p>
      </div>
    </div>
  );
};

export default NotFound;
