import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "./Components/features/userSlice";
import Loading from "./Components/LoadingScreen";
import AllPagesMasterNavbar from "../src/Components/MasterNavbar/AllPagesMasterNavbar";

function CaptureEmployee() {
  const user = useSelector(selectUser);
  const userEmail = user.email;

  const [imageSrc, setImageSrc] = useState(null);
  const [cameraReady, setCameraReady] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [employeeList, setemployeeList] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("session-token");

  useEffect(() => {
    setisloading(true);

    axios
      .get(`${BASEURL}/getemployees`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setisloading(false);
        const employees = res.data.map((employee) => ({
          code: employee["EMPLOYEE CODE"],
          name: employee["EMPLOYEE NAME"],
          imageUrl: employee["IMAGEURL"],
        }));
        setemployeeList(employees);
      })
      .catch((err) => {
        setisloading(false);

        console.error("Error fetching employees:", err);
      });
  }, []);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        setCameraReady(true);
      }
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  const captureImage = () => {
    if (cameraReady && videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const image = canvas.toDataURL("image/png");
      setImageSrc(image);

      stopCamera();
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setCameraReady(false);
    }
  };

  useEffect(() => {
    return () => stopCamera();
  }, []);

  const handleSave = async () => {
    if (!imageSrc || !selectedEmployee) {
      alert("Please capture an image and select an employee before saving.");
      return;
    }

    // Convert Base64 image to Blob
    const base64Image = imageSrc.split(",")[1]; // Remove the data:image/png;base64, prefix
    const byteCharacters = atob(base64Image);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(null)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const imageBlob = new Blob([byteArray], { type: "image/png" });

    // Create FormData object
    const formData = new FormData();
    formData.append("imageValue", imageBlob, "employee_image.png"); // Append Blob with filename

    try {
      setisloading(true); // Show loading indicator
      const response = await axios.post(
        "https://devapi.tech23.net/fileupload/uploadImage",
        formData,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
            "Content-Type": "multipart/form-data", // Set the correct content type
          },
        }
      );

      setisloading(false); // Hide loading indicator
      if (response.status === 201) {
        const imageUrl = response.data; // Assuming the response has a 'url' field
        console.log("Image uploaded successfully:", imageUrl);

        // Now use the imageUrl for IMAGEPATH
        const updateResponse = await axios.post(
          `${BASEURL}/updateempimage`,
          {
            EMPCODE: selectedEmployee.code, // Employee Code from selectedEmployee
            IMAGEPATH: imageUrl, // URL of the uploaded image
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );

        if (updateResponse.status === 201) {
          alert("Employee image updated successfully!");
          closeModal();
          window.location.reload(); // Close modal after saving
        } else {
          alert("Failed to update image. Please try again.");
        }
      } else {
        alert("Failed to upload image. Please try again.");
      }
    } catch (error) {
      setisloading(false); // Hide loading indicator
      console.error("Error during image upload:", error);
      alert("An error occurred while uploading the image.");
    }
  };

  // Open Modal with Employee Code
  const openModal = (employee) => {
    setSelectedEmployee(employee); // Store selected employee
    setImageSrc(null); // Reset image
    setIsModalOpen(true);
    startCamera();
  };

  // Close Modal
  const closeModal = () => {
    setIsModalOpen(false);
    stopCamera();
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">
      <AllPagesMasterNavbar />

      <h1 className="text-2xl font-semibold text-blue-950 underline mt-6">
        CAPTURE EMPLOYEE IMAGE
      </h1>

      <div className="bg-white shadow-lg p-5 mt-6 w-11/12 lg:w-2/3 rounded-lg overflow-auto">
        <table className="table-auto w-full border-collapse border border-gray-200 border-r-2">
          <thead>
            <tr className="bg-blue-900 text-white border-r-2">
              <th className="border border-gray-300 p-2 ">Employee Code</th>
              <th className="border border-gray-300 p-2">Employee Name</th>
              <th className="border border-gray-300 p-2">Images</th>
              <th className="border border-gray-300 p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {employeeList.map((employee) => (
              <tr key={employee.code}>
                <td className="border border-gray-300 p-4 m-8 text-right">
                  {employee.code}
                </td>
                <td className="border border-gray-300 p-2">{employee.name}</td>
                <td className="border border-gray-300 p-2 text-center">
                  {employee.imageUrl ? (
                    <img
                      src={employee.imageUrl}
                      alt="Employee"
                      className="h-12 w-12 object-cover rounded-full text-center"
                    />
                  ) : (
                    "No Image"
                  )}
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  <button
                    onClick={() => openModal(employee)}
                    className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded"
                  >
                    Capture Image
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg ">
            {!imageSrc ? (
              <>
                <video
                  ref={videoRef}
                  className="w-96 h-72 bg-black"
                  autoPlay
                  muted
                ></video>
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                <button
                  onClick={captureImage}
                  className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded mt-4"
                >
                  Capture Image
                </button>
              </>
            ) : (
              <>
                <img
                  src={imageSrc}
                  alt="Captured"
                  className="border-2 border-gray-300 rounded-lg mt-4 w-96"
                />
                <button
                  onClick={startCamera}
                  className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded mt-4"
                >
                  Retake Image
                </button>
              </>
            )}
            <button
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded mt-4 ml-4"
            >
              Save
            </button>
            <button
              onClick={closeModal}
              className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mt-4 ml-4"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CaptureEmployee;
