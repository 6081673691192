import { Link } from "react-router-dom";
import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import LoaderSmall from "../../../../Components/LoadingSmall";
import { FaChartArea } from "react-icons/fa";
const FuelConsumptionperiodicvehiclewise = () => {
  const [isLoading2, setLoading2] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [VehicleNo, setVehicleNo] = useState([]);
  const [isLoading3, setLoading3] = useState(false);
  const [selvehicleNo, setSelVehicleNo] = useState("");
  const [data, setData] = useState([]);
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  // if (isloading) {
  //   return <LoadingScreen />;
  // }
  const [chartType, setChartType] = useState("bar");
  useEffect(() => {
    getVehicleNo();
    AnalyzeBasedOnClient();
    setisloading(false);
  }, [fromdate, todate, selvehicleNo]);

  const getVehicleNo = () => {
    setLoading3(true);
    axios
      .get(`${BASEURL}/getVehicleList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicleNo(response.data);
        setLoading3(false);
      })
      .catch((error) => {
        console.error("Error fetching VehicleNo:", error);
        setLoading3(false);
      });
  };
  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  const AnalyzeBasedOnClient = () => {
    // setLoading2(true);
    const stertDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log(
      "stertDatePass",
      stertDatePass,
      "endDatePass",
      endDatePass,
      selvehicleNo
    );
    if (isValidDate(stertDatePass) && isValidDate(endDatePass)) {
      axios
        .post(
          `${BASEURL}/fuelConsumptionperiodicvehiclewise`,
          {
            STARTDATE: stertDatePass,
            ENDDATE: endDatePass,
            // VEHICLE: selvehicleNo,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };
  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  const filteredData = data.filter((item) => {
    const industryMatch =
      !selvehicleNo || selvehicleNo === "" || item?.VEHICLE === selvehicleNo;

    return industryMatch;
  });
  if (isloading) {
    return <LoadingScreen />;
  }

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>
        </div>
        <div
          className="lg:flex items-center lg:ml-10"
          style={{ zIndex: "100" }}
        >
          <aside>
            <p className="mt-2  lg:mt-0 lg:ml-[100px]">Vehicle :</p>
          </aside>
          {isLoading3 === true ? (
            <LoaderSmall />
          ) : (
            <Select
              className="route_select"
              onChange={(e) => {
                setSelVehicleNo(e.value);
              }}
              value={
                selvehicleNo
                  ? { label: selvehicleNo, value: selvehicleNo }
                  : { label: "Select Vehicle", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...VehicleNo.map((item) => ({
                  value: item?.["VEHICLE REG NO"],
                  label: item?.["VEHICLE REG NO"],
                })),
              ]}
            />
          )}
        </div>
      </div>
      {activeDates && (
        <div className="date__custom">
          {/* Date filter buttons */}
          <div className="flex justify-between">
            <h1>Recommended</h1>
          </div>
          <button onClick={() => handleDateFilterChange("last3days")}>
            Last 3 Days
          </button>
          <button onClick={() => handleDateFilterChange("last7days")}>
            Last 7 Days
          </button>

          <button onClick={() => handleDateFilterChange("last30days")}>
            Last 30 Days
          </button>
          <h1>Calendar months</h1>
          <button onClick={() => handleDateFilterChange("lastMonth")}>
            Last Month
          </button>
          <button onClick={() => handleDateFilterChange("lastQuarter")}>
            Last Quarter
          </button>
          <button onClick={() => handleDateFilterChange("lastYear")}>
            Last Year
          </button>
          <h1>Custom date</h1>
          <main className="flex mx-1 gap-3">
            <div className="">
              <div>
                <p>Start Date :</p>
              </div>
              <div>
                <input
                  value={fromdate.split("T")[0]}
                  type="date"
                  onClick={() => setActiveDates(true)}
                  onChange={(e) => {
                    setFromdate(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="">
              <div>
                <p>End Date :</p>
              </div>
              <div>
                <input
                  value={todate.split("T")[0]}
                  type="date"
                  onClick={() => setActiveDates(true)}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      alert("Please select a valid todate");
                    } else {
                      setTodate(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </main>
        </div>
      )}

      <aside style={{ display: "flex", flexDirection: "column", gap: "100px" }}>
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            marginTop: "15px",
            height: "40vh",
          }}
        >
          {isLoading2 ? (
            <Loading2 />
          ) : (
            <>
              {data && filteredData.length > 0 ? (
                <div>
                  <div className="chart__active__buttons">
                    <button
                      className={
                        chartType === "bar"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("bar")}
                    >
                      <IoBarChartOutline />
                    </button>
                    <button
                      className={
                        chartType === "pie"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("pie")}
                    >
                      <FaChartPie />
                    </button>

                    <button
                      className={
                        chartType === "scatter"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("scatter")}
                    >
                      <BiScatterChart />
                    </button>
                    <button
                      className={
                        chartType === "area"
                          ? "active__chart "
                          : "not__active__chart  "
                      }
                      onClick={() => handleButtonClick("area")}
                    >
                      <FaChartArea />
                    </button>
                  </div>
                  {chartType === "bar" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "multi-series-chart",
                        },
                        xaxis: {
                          categories: filteredData.map((item) => item?.VEHICLE),
                          title: {
                            text: "Month and Date",
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "TOTAL LITRES",
                            },
                          },
                          {
                            title: {
                              text: "AMOUNT",
                            },
                            opposite: true,
                          },
                          // Add more y-axis configurations for other parameters as needed
                        ],
                        dataLabels: {
                          enabled: false,
                        },
                      }}
                      series={[
                        {
                          name: "TOTAL LITRES",
                          data: filteredData.map((item) => item?.LITRES),
                        },
                        {
                          name: "AMOUNT",
                          data: filteredData.map((item) => item?.AMOUNT),
                        },
                        // Add more series for other parameters as needed
                      ]}
                      type="bar"
                      height={300}
                    />
                  )}
                  {chartType === "area" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "area-chart", // Change the ID for the area chart
                        },
                        xaxis: {
                          categories: filteredData.map((item) => item?.VEHICLE),
                          title: {
                            text: "PERIOD", // X-axis label
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "AMOUNT", // Y-axis label
                            },
                          },
                        ],
                        dataLabels: {
                          enabled: false, // Disable the data labels for area chart
                        },
                      }}
                      series={[
                        {
                          name: "LITRES",
                          data: filteredData.map((item) => item?.LITRES),
                        },
                      ]}
                      type="area" // Change chart type to "area"
                      height={300}
                    />
                  )}
                  {chartType === "pie" && (
                    <div className="flex__pai">
                      <aside className="for__single__pai">
                        <ReactApexChart
                          options={{
                            labels: filteredData.map((item) => item?.VEHICLE),
                            title: {
                              text: "Pie Chart of Amount",
                            },
                            dataLabels: {
                              enabled: true,
                            },
                            tooltip: {
                              enabled: true,
                              y: {
                                formatter: function (val, opts) {
                                  let index = opts.dataPointIndex;
                                  let name = "AMOUNT";
                                  return name + ": " + val;
                                },
                              },
                            },
                          }}
                          series={filteredData.map((item) => item?.AMOUNT ?? 0)}
                          type="pie"
                          height={300}
                        />
                      </aside>
                      <aside className="for__single__pai">
                        {" "}
                        <ReactApexChart
                          options={{
                            labels: filteredData.map((item) => item?.VEHICLE),
                            title: {
                              text: "Pie Chart for liters",
                            },
                            dataLabels: {
                              enabled: true,
                            },
                            tooltip: {
                              enabled: true,
                              y: {
                                formatter: function (val, opts) {
                                  let index = opts.dataPointIndex;
                                  let name = "LITERS";
                                  return name + ": " + val;
                                },
                              },
                            },
                          }}
                          series={filteredData.map((item) => item?.LITRES ?? 0)}
                          type="pie"
                          height={300}
                        />
                      </aside>
                    </div>
                  )}
                  {chartType === "scatter" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "scatter-chart",
                        },
                        xaxis: {
                          type: "category",
                          categories: filteredData.map((item) => item?.VEHICLE), // Convert to milliseconds

                          title: {
                            text: "VEHICLE",
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "Amount",
                            },
                          },
                          {
                            opposite: true,
                            title: {
                              text: "Liters",
                            },
                          },
                        ],
                        title: {
                          text: "Scatter Chart",
                        },
                      }}
                      series={[
                        {
                          name: "Amount",
                          data: filteredData.map((item) => ({
                            x: item?.VEHICLE,
                            y: item?.AMOUNT,
                          })),
                        },
                        {
                          name: "Liters",
                          data: filteredData.map((item) => ({
                            x: item?.VEHICLE,
                            y: item?.LITRES,
                          })),
                        },
                      ]}
                      type="scatter"
                      height={300}
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    marginTop: "50px",
                    textDecoration: "underline",
                  }}
                >
                  {selvehicleNo && <p> No Data Available</p>}
                  {!selvehicleNo && <p> Select Vehicle</p>}
                </div>
              )}
            </>
          )}
        </div>
      </aside>
      <Footer />
    </div>
  );
};

export default FuelConsumptionperiodicvehiclewise;
