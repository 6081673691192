import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import axios from "axios";
import moment from "moment";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "../../../../Components/Footer";
import "../../../../CSS/AnalyticsBasedOnProfitandLoss.css";
import { useSelector } from "react-redux";

function AnalyticsBasedOnProfitandLossTrading() {
  const [isloading, setIsloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [startdate, setStartdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [enddate, setEnddate] = useState(new Date().toISOString());
  const [paldata, setPALdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const CompanyCode = useSelector(
    (state) => state?.user?.companyCode?.companyCode
  );
  const ProfitGroupCounts = {};

  const handleBackAndReload = () => {
    window.history.back(); // Go back in history
    // Reload the current page
  };

  useEffect(() => {
    getDetails();
    setIsloading(false);
  }, [startdate, enddate]);

  function handleView(value1, value2) {
    navigate("/AnalyticsBasedOnNominalActivityTrading", {
      state: { value1, value2, startdate, enddate, orgCode: CompanyCode },
    });
  }

  const getDetails = () => {
    setLoading2(true);
    axios
      .post(
        `${BASEURL}/ProfitAndLoss`,
        {
          startdate: moment(startdate).format("DD/MM/YYYY"),
          enddate: moment(enddate).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setPALdata(response.data);
        setLoading2(false);
      })
      .catch((error) => {
        setLoading2(false);
        console.log("Error Fetching Profit and Loss Data : ", error);
      });
  };

  const ExcelData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("ProfitandLoss");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [20, 5, 30, 30, 5, 10, 10, 30, 15]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "MAIN GROUP LEVEL",
      "MAIN GROUP ID",
      "MAIN GROUP",
      "SUB GROUP",
      "SUB GROUP INDEX",
      "INCOME EXPENSE",
      "ACCOUNT CODE",
      "ACCOUNT NAME",
      "AMOUNT",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "ProfitandLoss.xlsx";
      link.click();
    });
  };

  const PdfData = () => {
    const doc = new jsPDF();

    doc.text("Profit And Loss", 10, 10);

    const columns = [
      "MAIN GROUP LEVEL",
      "MAIN GROUP ID",
      "MAIN GROUP",
      "SUB GROUP",
      "SUB GROUP INDEX",
      "INCOME EXPENSE",
      "ACCOUNT CODE",
      "ACCOUNT NAME",
      "AMOUNT",
    ];
    const data = paldata.map((data) => [
      data.MAINGROUPLEVEL,
      data.MAINGROUPID,
      data.MAINGROUP,
      data.SUBGROUP,
      data.SUBGROUPINDEX,
      data.INCOMEXPENSE,
      data.ACCOUNTCODE,
      data.ACCOUNTNAME,
      data.AMOUNT,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("ProfitandLoss.pdf");
  };

  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            <button className="View" onClick={handleBackAndReload}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>Profit and Loss View</h2>
      </div>
      <div className="Profit_loss_box">
        <div className="Profit_loss_first">
          <div className="profit_from_date">
            <p style={{ width: "100px" }}>
              <b>Start date:</b>
            </p>
            <input
              value={startdate.split("T")[0]}
              className="as_at"
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid startdate");
                } else {
                  setStartdate(e.target.value);
                }
              }}
            ></input>
          </div>
          <div className="profit_to_date">
            <p style={{ width: "100px" }}>
              <b>End date:</b>
            </p>
            <input
              className="as_at"
              value={enddate.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid enddate");
                } else {
                  setEnddate(e.target.value);
                }
              }}
            ></input>
          </div>

          <div className="profit_search">
            <p>
              <b>Search:</b>
            </p>
            <input
              className="input_Like"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      {isLoading2 ? (
        <Loading2 />
      ) : (
        <>
          <div className="stock_icons">
            <div>
              <RiFileExcel2Line onClick={ExcelData} size={35} />
            </div>
            <div>
              <BsFileEarmarkPdf onClick={PdfData} size={35} />
            </div>
          </div>
          <aside className="aside">
            <div
              style={{
                marginTop: "20px",
                marginLeft: "10px",

                width: "98%",
              }}
            >
              <table className="table_1">
                <thead className="thead_stock">
                  <tr className="tr_test1">
                    <td>MAIN GROUP LEVEL</td>

                    <td>MAIN GROUP</td>
                    <td>SUB GROUP</td>

                    <td>INCOME EXPENSE</td>
                    <td>ACCOUNT CODE</td>
                    <td>ACCOUNT NAME</td>
                    <td style={{ paddingLeft: "15px" }}>AMOUNT</td>
                    <td style={{ fontWeight: "500", paddingLeft: "20px" }}>
                      ACTION
                    </td>
                  </tr>
                </thead>
                <tbody className="stock_tbody">
                  {paldata
                    .filter((stock) => {
                      const searchKeywords = searchQuery
                        .toLowerCase()
                        .split(" ");
                      return searchKeywords.every((keyword) =>
                        Object.keys(stock)
                          .filter((key) => key !== "AMOUNT")
                          .map((key) => String(stock[key]).toLowerCase())
                          .join(" ")
                          .includes(keyword)
                      );
                    })
                    .map((data) => {
                      const cleanedmainGroup = data.MAINGROUPLEVEL.replace(
                        /\s+/g,
                        ""
                      ).toUpperCase();

                      const count = ProfitGroupCounts[cleanedmainGroup] || 0;
                      ProfitGroupCounts[cleanedmainGroup] = count + 1;
                      return (
                        <tr className="tr_test2">
                          {count === 0 && (
                            <td className="asset">{data.MAINGROUPLEVEL}</td>
                          )}
                          {count > 0 && <td></td>}

                          <td style={{ width: "190px", padding: "10px" }}>
                            {data.MAINGROUP}
                          </td>
                          <td style={{ width: "170px" }}>{data.SUBGROUP}</td>

                          <td>{data.INCOMEXPENSE}</td>
                          <td>{data.ACCOUNTCODE}</td>
                          <td style={{ width: "180px" }}>{data.ACCOUNTNAME}</td>
                          <td
                            style={{ textAlign: "right", paddingRight: "10px" }}
                          >
                            {data.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            <button
                              className="button"
                              type="button"
                              onClick={() => {
                                handleView(
                                  data.ACCOUNTCODE,
                                  data.ACCOUNTNAME,
                                  startdate,
                                  enddate
                                );
                              }}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </aside>
        </>
      )}
      <Footer />
    </div>
  );
}

export default AnalyticsBasedOnProfitandLossTrading;
