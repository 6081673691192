import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const VehicleWiseExpenses = () => {
  const navigate = useNavigate();
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [VehicleNo, setVehicleNo] = useState([]);
  var [selvehicleNo, setSelVehicleNo] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [data, setData] = useState([]);
  const [datawithVehicleNo, setDataWithVehicleNo] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isLoading2, setLoading2] = useState(false);
  const [driver, setDriver] = useState([]);
  var [seldrivecode, setselDriveCode] = useState("");
  var [seldrivername, setseldrivername] = useState("");
  const [datawithvehinoanddrivercode, setdatawithvehinoanddrivercode] =
    useState([]);
  const [datawithdrivercode, setDatawithdrivercode] = useState([]);

  useEffect(() => {
    getVehicleNo();
    getDriver();
    AnalyzeBasedOnVehicleNo();
    setisloading(false);
  }, [selvehicleNo, fromdate, todate, seldrivecode, seldrivername]);

  const getVehicleNo = () => {
    axios
      .get(`${BASEURL}/VehicleNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicleNo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching VehicleNo:", error);
      });
  };

  const getDriver = () => {
    axios
      .get(`${BASEURL}/DriverDetailss`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setDriver(response.data);
      })
      .catch((error) => {
        console.error("Error fetching itemcode:", error);
      });
  };
  const handleItemName = (e) => {
    const selectedValue = e;
    const selectedOption = driver.find(
      (obj) => obj.EMPLOYEENAME === selectedValue
    );
    if (selectedValue === "") {
      setseldrivername("");
      setselDriveCode("");
    } else if (selectedOption === "") {
      setselDriveCode("");
      setseldrivername("");
    } else {
      setseldrivername(selectedValue);
      setselDriveCode(selectedOption.OT_JOB_DRIVER_CODE);
    }
  };

  const handleItemCode = (e) => {
    const selectedValue = e;
    const selectedOption = driver.find(
      (obj) => obj.OT_JOB_DRIVER_CODE === selectedValue
    );
    if (selectedValue === "") {
      setseldrivername("");
      setselDriveCode("");
    } else if (selectedOption === "") {
      setseldrivername("");
      setselDriveCode("");
    } else {
      setselDriveCode(selectedValue);
      setseldrivername(selectedOption.EMPLOYEENAME);
    }
  };
  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  const AnalyzeBasedOnVehicleNo = () => {
    const stertDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log(
      "stertDatePass",
      stertDatePass,
      "endDatePass",
      endDatePass,
      "selvehicleNo",
      selvehicleNo
    );
    if (
      isValidDate(stertDatePass) &&
      isValidDate(endDatePass) &&
      selvehicleNo
    ) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/vehiclewiseanalysis`,
          {
            STARTDATE: stertDatePass,
            ENDDATE: endDatePass,
            VEHICLE: selvehicleNo,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };

  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>
          <div
            className="lg:flex items-center lg:ml-10"
            style={{ zIndex: "100" }}
          >
            <aside>
              <p className="mt-2  lg:mt-0 lg:ml-[100px] ">Vehicle Number :</p>
            </aside>
            <Select
              className="route_select "
              onChange={(e) => {
                setSelVehicleNo(e.value);
              }}
              value={
                selvehicleNo
                  ? { label: selvehicleNo, value: selvehicleNo }
                  : { label: "Select Vehicle", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...VehicleNo.map((item) => ({
                  value: item?.OT_JOB_VEHICLE_NO,
                  label: item?.OT_JOB_VEHICLE_NO,
                })),
              ]}
            />
          </div>
        </div>

        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>

      <section className="flex mt-5 flex-wrap gap-3 justify-center lg:justify-between flex-col-reverse lg:flex-row">
        {data.length > 0 && (
          <div className="overflow-y-auto max-h-[400px] px-4  lg:w-[25%] overflow-x-hidden ">
            <table className="custom-table text-sm overflow__y__ relative w-full ">
              <thead
                className="header-cell sticky top-[0px] left-[10px] text-[10px]"
                style={{ zIndex: 20 }}
              >
                <tr>
                  <td className="">MONTH END</td>
                  <td className="text-right">TOTAL COST</td>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="text-[10px]"
                    >
                      {" "}
                      <td className=" ">
                        {item.MONTHEND
                          ? moment(item?.MONTHEND).format(" MMM YYYY")
                          : "0"}
                      </td>
                      <td className=" text-right">
                        {item["TOTALCOST"] ? item["TOTALCOST"] : "0"}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr className="font-bold text-[10px]">
                  <td className="text-left">TOTAL</td>

                  <td className="text-right">
                    {data.reduce(
                      (sum, item) => sum + (item["TOTALCOST"] || 0),
                      0
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        <div
          style={{ margin: "0 auto" }}
          onClick={() => setActiveDates(false)}
          className="lg:w-[70%] w-full"
        >
          {" "}
          {isLoading2 ? (
            <Loading2 />
          ) : (
            <>
              {data.length > 0 && (
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>

                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
              )}

              {data.length > 0 ? (
                <div>
                  {chartType === "bar" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "bar-chart", // Change the ID for the bar chart
                        },
                        xaxis: {
                          categories: data?.map((item) =>
                            moment(item?.MONTHEND).format(" MMM YYYY")
                          ), // Format month and date (e.g., "May 2")
                          title: {
                            text: "Month and Date", // X-axis label
                          },
                        },
                        yaxis: {
                          title: {
                            text: "Total Cost", // Y-axis label
                          },
                        },
                        dataLabels: {
                          enabled: false, // Disable the data labels for bar chart
                        },
                      }}
                      series={[
                        {
                          name: "Total Cost",
                          data: data?.map((item) => item?.TOTALCOST),
                        },
                      ]}
                      type="bar" // Change chart type to "bar"
                      height={300}
                    />
                  )}
                  {chartType === "area" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "area-chart", // Change the ID for the area chart
                        },
                        xaxis: {
                          categories: data.map((item) =>
                            moment(item?.MONTHEND).format(" MMM YYYY")
                          ),
                          title: {
                            text: "MONTHEND", // X-axis label
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "AMOUNT", // Y-axis label
                            },
                          },
                        ],
                        dataLabels: {
                          enabled: false, // Disable the data labels for area chart
                        },
                      }}
                      series={[
                        {
                          name: "TOTAL COST",
                          data: data.map((item) => item?.TOTALCOST),
                        },
                      ]}
                      type="area" // Change chart type to "area"
                      height={300}
                    />
                  )}
                  {chartType === "pie" && (
                    <ReactApexChart
                      options={{
                        labels: data?.map((item) =>
                          moment(item?.MONTHEND).format(" MMM YYYY")
                        ),

                        title: {
                          text: "Pie Chart", // Title of the pie chart
                        },
                        dataLabels: {
                          enabled: true, // Enable data labels for pie chart
                        },
                        tooltip: {
                          enabled: true,
                          y: {
                            formatter: function (val, opts) {
                              let index = opts.dataPointIndex;
                              let label = "TOTAL COST ";
                              return label + ": " + val;
                            },
                          },
                        },
                      }}
                      series={data?.map((item) => item?.TOTALCOST)}
                      type="pie" // Set chart type to "pie"
                      height={300}
                    />
                  )}
                  {chartType === "scatter" && (
                    <ReactApexChart
                      options={{
                        xaxis: {
                          categories: data?.map((item) =>
                            moment(item?.MONTHEND).format("MMM YYYY")
                          ),
                          labels: {
                            formatter: function (val) {
                              return val?.slice(0, 10); // Slice to include only the date (e.g., "01 Jan 2023")
                            },
                            title: {
                              text: "Month and Date", // X-axis label
                            },
                          },
                        },
                        yaxis: {
                          title: {
                            text: "Total Cost", // Y-axis label
                          },
                        },
                        title: {
                          text: "Scatter Chart",
                        },
                      }}
                      series={[
                        {
                          name: "Total Cost",
                          data: data?.map((item) => ({
                            x: new Date(item?.MONTHEND),
                            y: item?.TOTALCOST,
                          })),
                        },
                      ]}
                      type="scatter"
                      height={300}
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    marginTop: "50px",
                    textDecoration: "underline",
                  }}
                >
                  {selvehicleNo && <p> No Data Available</p>}
                  {!selvehicleNo && <p> Select Vehicle</p>}
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default VehicleWiseExpenses;
