import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";

import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";

import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
import ProductionQuantitiesPeriodwise from "./ProductionQuantitiesPeriodwise";
import ProductTotalQuantitiesProduced from "./ProductTotalQuantitiesProduced";
import ProductionForAGivenItem from "./ProductionForAGivenItem";
import TradingAnalyticsNavbar from "../../../../Components/TradingAnalyticsNavbar";
const ProductionQuantities = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const [selectedComponent, setSelectedComponent] = useState({
    value: "ProductionQuantitiesPeriodwise",
    label: "Production Quantities Period wise",
  });
  const options = [
    {
      value: "ProductionQuantitiesPeriodwise",
      label: "Production Quantities Period wise",
    },
    {
      value: "ProductTotalQuantitiesProduced",
      label: "Product Total Quantities Produced",
    },
    {
      value: "ProductionForAGivenItem",
      label: "Production For A Given Item",
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TradingAnalyticsNavbar />
      <header className="header__of__main__dashboard flex justify-between items-end px-3 hide__when__print">
        <h2 className="header__of__page">Production Quantities</h2>

        <div className="w-[400px] mx-2 " style={{ zIndex: 999 }}>
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent &&
          selectedComponent.value === "ProductionQuantitiesPeriodwise" && (
            <ProductionQuantitiesPeriodwise />
          )}
        {selectedComponent &&
          selectedComponent.value === "ProductTotalQuantitiesProduced" && (
            <ProductTotalQuantitiesProduced />
          )}
        {selectedComponent &&
          selectedComponent.value === "ProductionForAGivenItem" && (
            <ProductionForAGivenItem />
          )}
      </div>
    </div>
  );
};

export default ProductionQuantities;
