import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const FuelConsumptionRoutewise = () => {
  const [isLoading2, setLoading2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [toDate, setToDate] = useState(new Date().toISOString());
  const [selectedRoute, setSelectedRoute] = useState(null);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);
  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  const fetchData = () => {
    const stertDatePass =
      fromDate.slice(8, 10) +
      "/" +
      fromDate.slice(5, 7) +
      "/" +
      fromDate.slice(0, 4);
    const endDatePass =
      toDate.slice(8, 10) + "/" + toDate.slice(5, 7) + "/" + toDate.slice(0, 4);
    console.log("stertDatePass", stertDatePass, "endDatePass", endDatePass);

    setIsLoading(true);
    if (isValidDate(stertDatePass) && isValidDate(endDatePass)) {
      axios
        .post(
          `${BASEURL}/fuelConsumptionRoutewise`,
          {
            STARTDATE: stertDatePass,
            ENDDATE: endDatePass,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          setData(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  const filteredData = selectedRoute
    ? selectedRoute.value === "" // Check if "All" is selected
      ? data // Return all data if "All" is selected
      : data.filter((item) => item?.ROUTENAME === selectedRoute.value)
    : data;

  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromDate(startDate);
    setToDate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromDate.slice(8, 10) +
                  "-" +
                  fromDate.slice(5, 7) +
                  "-" +
                  fromDate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {toDate.slice(8, 10) +
                  "-" +
                  toDate.slice(5, 7) +
                  "-" +
                  toDate.slice(0, 4)}
              </span>
            </div>
          </div>
          <div
            className="lg:flex items-center lg:ml-10"
            style={{ zIndex: "100" }}
          >
            {/* filter */}
            <aside>
              <p className="mt-2  lg:mt-0 lg:ml-[100px]">Route :</p>
            </aside>
            <div>
              <Select
                className="route_select"
                value={selectedRoute}
                onChange={(selectedOption) => {
                  setSelectedRoute(selectedOption);
                }}
                options={[
                  { value: "", label: "All" }, // Option to return all data
                  ...data.map((item) => ({
                    value: item?.ROUTENAME,
                    label: item?.ROUTENAME,
                  })),
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {activeDates && (
        <div className="date__custom">
          {/* Date filter buttons */}
          <div className="flex justify-between">
            <h1>Recommended</h1>
          </div>
          <button onClick={() => handleDateFilterChange("last3days")}>
            Last 3 Days
          </button>
          <button onClick={() => handleDateFilterChange("last7days")}>
            Last 7 Days
          </button>

          <button onClick={() => handleDateFilterChange("last30days")}>
            Last 30 Days
          </button>
          <h1>Calendar months</h1>
          <button onClick={() => handleDateFilterChange("lastMonth")}>
            Last Month
          </button>
          <button onClick={() => handleDateFilterChange("lastQuarter")}>
            Last Quarter
          </button>
          <button onClick={() => handleDateFilterChange("lastYear")}>
            Last Year
          </button>
          <h1>Custom date</h1>
          <main className="flex mx-1 gap-3">
            <div className="">
              <div>
                <p>Start Date :</p>
              </div>
              <div>
                <input
                  value={fromDate.split("T")[0]}
                  type="date"
                  onClick={() => setActiveDates(true)}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="">
              <div>
                <p>End Date :</p>
              </div>
              <div>
                <input
                  value={toDate.split("T")[0]}
                  type="date"
                  onClick={() => setActiveDates(true)}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      alert("Please select a valid todate");
                    } else {
                      setToDate(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </main>
        </div>
      )}

      <div style={{ width: "95%", margin: "0 auto" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {filteredData.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>
                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart",
                      },
                      xaxis: {
                        categories: filteredData.map((item) => item?.ROUTENAME),
                        title: {
                          text: "ROUTE NAME",
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "TOTAL LITRES",
                          },
                        },
                        {
                          title: {
                            text: "AMOUNT",
                          },
                          opposite: true,
                        },
                      ],
                      dataLabels: {
                        enabled: false,
                      },
                    }}
                    series={[
                      {
                        name: "TOTAL LITRES",
                        data: filteredData.map((item) => item?.LITRES),
                      },
                      {
                        name: "AMOUNT",
                        data: filteredData.map((item) => item?.AMOUNT),
                      },
                    ]}
                    type="bar"
                    height={400}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: filteredData.map((item) => item?.ROUTENAME),
                        title: {
                          text: "ROUTENAME", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "AMOUNT",
                        data: filteredData.map((item) => item?.AMOUNT),
                      },
                      {
                        name: "LITRES",
                        data: filteredData.map((item) => item?.LITRES),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <div className="flex__pai">
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}
                      <ReactApexChart
                        options={{
                          labels: filteredData.map((item) =>
                            item?.ROUTENAME?.slice(0, 15)
                          ),
                          title: {
                            text: "Pie Chart of TOTAL LITRES",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "LITRES";
                                return name + ": " + val;
                              },
                            },
                          },
                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={filteredData.map((item) => item?.LITRES ?? 0)}
                        type="pie"
                      />
                    </aside>
                    <aside className="for__single__pai">
                      {" "}
                      {/* Adjust the width of each pie chart */}{" "}
                      <ReactApexChart
                        options={{
                          labels: filteredData.map((item) =>
                            item?.ROUTENAME?.slice(0, 15)
                          ),
                          title: {
                            text: "Pie Chart for AMOUNT",
                          },
                          dataLabels: {
                            enabled: true,
                          },
                          tooltip: {
                            enabled: true,
                            y: {
                              formatter: function (val, opts) {
                                let index = opts.dataPointIndex;
                                let name = "AMOUNT";
                                return name + ": " + val;
                              },
                            },
                          },
                          width: "100%", // Set width to 100% of container
                          height: "100%", // Set height to 100% of container
                        }}
                        series={filteredData.map((item) => item?.AMOUNT ?? 0)}
                        type="pie"
                      />
                    </aside>
                  </div>
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart",
                        zoom: {
                          enabled: false,
                        },
                      },
                      xaxis: {
                        // type: "datetime",
                        title: {
                          text: "ROUTE NAME",
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "Amount",
                          },
                        },
                        {
                          opposite: true,
                          title: {
                            text: "Liters",
                          },
                        },
                      ],
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "Amount",
                        data: filteredData.map((item) => ({
                          x: item?.ROUTENAME,
                          y: item?.AMOUNT,
                        })),
                      },
                      {
                        name: "Liters",
                        data: filteredData.map((item) => ({
                          x: item?.ROUTENAME,
                          y: item?.LITRES,
                        })),
                      },
                    ]}
                    type="scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO RESULTS FOUND
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FuelConsumptionRoutewise;
