import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Loading2 from "../../../../Components/Loading2";
import { Link } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const FuelPurchaseSupplierWise = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading2, setLoading2] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [activeDates, setActiveDates] = useState(false);
  const [todate, setTodate] = useState(new Date().toISOString());
  const [analyticsData, setanalyticsData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [dateRange, setDateRange] = useState([
    // Define dateRange
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useEffect(() => {
    if (selectedDateRange) {
      handleDateFilterChange(selectedDateRange);
    }
  }, [selectedDateRange]);
  useEffect(() => {
    fetchAnalysis(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [fromdate, todate]);
  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  async function fetchAnalysis() {
    setLoading2(true);
    const formattedFromDate = fromdate
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/");
    const formattedToDate = todate.split("T")[0].split("-").reverse().join("/");
    console.log(formattedFromDate);
    try {
      if (isValidDate(formattedFromDate) && isValidDate(formattedToDate)) {
        const response = await axios.post(
          `${BASEURL}/fuelPurchasesSupplierwise`,
          {
            STARTDATE: formattedFromDate,
            ENDDATE: formattedToDate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        console.log(response);
        setLoading2(false);
        setanalyticsData(response.data);
      } else {
        // alert("Select start and end date");
        setLoading2(false);
      }
    } catch (error) {
      console.error("Error fetching analysis data:", error);
      alert(
        "An error occurred while fetching analysis data. Please try again."
      );
    } finally {
      // Ensure loading state is updated
      setLoading2(false);
    }
  }
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };
  const [chartType, setChartType] = useState("bar");
  const filterRef = useRef(null);
  const handleButtonClick = (type) => {
    setChartType(type);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div
          onClick={() => setActiveDates(!activeDates)}
          className="cursor-pointer flex items-center gap-2 "
        >
          <h2 className="text-2xl">
            {" "}
            <BsCalendar2Date />
          </h2>
          <span className="bg-white inline-block px-1 rounded border-1px border-solid">
            {fromdate.slice(8, 10) +
              "-" +
              fromdate.slice(5, 7) +
              "-" +
              fromdate.slice(0, 4)}
          </span>{" "}
          -{" "}
          <span className="bg-white inline-block px-1 rounded">
            {todate.slice(8, 10) +
              "-" +
              todate.slice(5, 7) +
              "-" +
              todate.slice(0, 4)}
          </span>
        </div>
        <div className="w-[200px]">
          {" "}
          {/* <Select options={options} onChange={handleDateFilterChange} /> */}
        </div>

        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>
      <div
        style={{ width: "95%", margin: "0 auto", marginTop: "15px" }}
        onClick={() => setActiveDates(false)}
      >
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {analyticsData.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>

                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart", // Change the ID for the bar chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) => item?.SUPPLIER),
                        title: {
                          text: "Month", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "Amount", // Y-axis label
                          },
                        },
                        {
                          title: {
                            text: "Litres", // Y-axis label
                          },
                          opposite: true,
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for bar chart
                      },
                    }}
                    series={[
                      {
                        name: "Amount",
                        data: analyticsData.map((item) => item?.COST),
                      },
                      {
                        name: "Litres",
                        data: analyticsData.map((item) => item?.TOTALLITRES),
                      },
                    ]}
                    type="bar" // Change chart type to "bar"
                    height={300}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) => item?.SUPPLIER),
                        title: {
                          text: "SUPPLIER", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: " COST",
                        data: analyticsData.map((item) => item?.COST),
                      },
                      {
                        name: "TOTAL LITRES",
                        data: analyticsData.map((item) => item?.TOTALLITRES),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <ReactApexChart
                    options={{
                      labels: analyticsData.map((item) => item?.SUPPLIER),
                      title: {
                        text: "Pie Chart", // Title of the pie chart
                      },
                      dataLabels: {
                        enabled: true, // Enable data labels for pie chart
                      },
                      tooltip: {
                        enabled: true,
                        y: [
                          {
                            formatter: function (val, opts) {
                              let index = opts.dataPointIndex;
                              let name = "AMOUNT";
                              return name + ": " + val;
                            },
                          },
                        ],
                      },
                    }}
                    series={analyticsData.map((item) => item?.COST)}
                    type="pie" // Set chart type to "pie"
                    height={300}
                  />
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart", // Change the ID for the scatter chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) => item?.SUPPLIER), // Convert to milliseconds
                        title: {
                          text: "Supplier", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                        {
                          opposite: true,
                          title: {
                            text: "LITRES", // Y-axis label for the right side
                          },
                        },
                      ],
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "Amount",
                        data: analyticsData.map((item) => ({
                          x: item?.SUPPLIER, // Convert to milliseconds
                          y: item?.COST,
                        })),
                      },
                      {
                        name: "Litres",
                        data: analyticsData.map((item) => ({
                          x: item?.SUPPLIER, // Convert to milliseconds
                          y: item?.TOTALLITRES,
                        })),
                        yAxis: 1, // Assign this series to the right y-axis
                      },
                    ]}
                    type="scatter" // Set chart type to "scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO DATA AVAILABLE
              </div>
            )}
          </>
        )}
      </div>

      {/* <div style={{ width: "95%", margin: "0 auto" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {analyticsData.length > 0 ? (
              <ReactApexChart
                options={{
                  chart: {
                    id: "bar-chart", // Change the ID for the bar chart
                  },
                  xaxis: {
                    categories: analyticsData.map((item) => item?.SUPPLIER),
                    title: {
                      text: "Supplier", // X-axis label
                    },
                  },
                  yaxis: [
                    {
                      title: {
                        text: "Amount", // Y-axis label
                      },
                    },
                    {
                      title: {
                        text: "Litres", // Y-axis label
                      },
                      opposite: true,
                    },
                  ],
                  dataLabels: {
                    enabled: false, // Disable the data labels for bar chart
                  },
                }}
                series={[
                  {
                    name: "Amount",
                    data: analyticsData.map((item) => item?.COST),
                  },
                  {
                    name: "Litres",
                    data: analyticsData.map((item) => item?.TOTALLITRES),
                  },
                ]}
                type="bar" // Change chart type to "bar"
                height={300}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                NO DATA AVAILABLE
              </div>
            )}
          </>
        )}
      </div> */}
    </div>
  );
};

export default FuelPurchaseSupplierWise;
