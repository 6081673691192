import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../../Components/features/userSlice";
import { useDispatch } from "react-redux";
import Footer from "../../../../Components/Footer";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";

function FinancialDashboard() {
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };
  return (
    <div>
      <TransportDashboardsNavbar />
      <div className="h2">
        <h1>Financial Dashboards</h1>
      </div>
      <div className="dashboard">
        {/* <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnNominalActivity");
          }}
        >
          <div className="card-content">
            <div className="card-title">General Ledger</div>
          </div>
        </div> */}

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnTrialBalance");
          }}
        >
          <div className="card-content">
            <div className="card-title">Trial Balance</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnProfitandLoss");
          }}
        >
          <div className="card-content">
            <div className="card-title">Profit and Loss</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnBalanceSheet");
          }}
        >
          <div className="card-content">
            <div className="card-title">Balance Sheet</div>
          </div>
        </div>

        {/* <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnCustomerStatement");
          }}
        >
          <div className="card-content">
            <div className="card-title">Customer Outstanding</div>
          </div>
        </div> */}

        {/* <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnSupplierStatement");
          }}
        >
          <div className="card-content">
            <div className="card-title">Supplier Outstanding</div>
          </div>
        </div> */}
        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnOutstandingDebtors");
          }}
        >
          <div className="card-content">
            <div className="card-title">Debtors Outstanding</div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnCreditorsOutstanding");
          }}
        >
          <div className="card-content">
            <div className="card-title">Creditors Outstanding</div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default FinancialDashboard;
