import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../../../Components/LoadingScreen";

const AnalyticsBasedOnCustomerStatement = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const authToken = window.sessionStorage.getItem("auth-token");
  const user = useSelector((state) => state.changeTheState);
  const location = useLocation();
  const Code = location.state.value1;
  const startdate1 = location.state.startdate;
  const enddate1 = location.state.enddate;
  const [startdate, setstartdate] = useState(
    startdate1
      ? startdate1
      : new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [enddate, setEnddate] = useState(enddate1);

  // const [startdate, setstartdate] = useState(formattedStartOfMonth);
  // const [enddate, setEnddate] = useState(formattedToday);

  const [isloading, setIsloading] = useState(true);
  const [initialData, setInitialdata] = useState([]);
  const [clientCode, setClientCode] = useState("");
  const [showmoredetails, setShowMoreDetails] = useState(false);
  const [search, setSearch] = useState("");
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
  const formattedStartOfMonth = startOfMonth.toISOString().split("T")[0];
  const formattedToday = today.toISOString().split("T")[0];
  const [totalCR, setTotalCR] = useState(0);
  const [totalDR, setTotalDR] = useState(0);
  const [balance, setBalance] = useState(0);
  const [zerotothirty, setZeroToThirty] = useState("");
  const [thirtytosixty, setThirtyToSixty] = useState("");
  const [sixtytoninty, setSixtyToNinty] = useState("");
  const [aboveninty, setAboveNinty] = useState("");
  const [count, setCount] = useState({});
  const [orgname, setOrgname] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, SetCity] = useState("");
  const [country, setCountry] = useState("");
  const [clientname, setClientname] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [creditDays, setCreditDays] = useState("");
  const [mobileno, setMobileNo] = useState("");

  useEffect(() => {
    let totalCR = 0;
    let totalDR = 0;
    let balance = 0;

    initialData?.forEach((item) => {
      if (item?.DRCR === "DR") {
        totalDR += parseFloat(item?.AMOUNT);
      } else {
        totalCR += parseFloat(item?.AMOUNT);
      }
    });

    setTotalCR(totalCR);
    setTotalDR(totalDR);
    setBalance(Number(totalDR) - Number(totalCR));
  }, [initialData]);

  const filteredData = initialData?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    const sDate = item?.DOCDATE;
    const eDate = item?.DOCDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return ["TRANSACTIONTYPE", "DOCNO", "REFNO", "CURRENCY"].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${BASEURL}/getCompanyDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setOrgname(response?.data[0]?.ORGNAME);
        setAddress1(response?.data[0]?.ADDRESS1);
        setAddress2(response?.data[0]?.ADDRESS2);
        setAddress3(response?.data[0]?.ADDRESS3);
        SetCity(response?.data[0]?.CITY);
        setCountry(response?.data[0]?.COUNTRY);
        setIsloading(false);
      })
      .catch((err) => {
        console.log("error while fetching company details", err);
      });
  }, []);

  useEffect(() => {
    // setIsloading(true);
    if (enddate >= startdate && enddate && startdate) {
      axios
        .post(
          `${BASEURL}/getCLientDetails`,
          {
            CLIENTCODE: Code,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setClientname(response?.data[0]?.CLIENTNAME);
          setCreditDays(response?.data[0]?.CREDITDAYS);
          setCreditLimit(response?.data[0]?.CREDITLIMIT);
          setMobileNo(response?.data[0]?.MOBILENO);
        })
        .catch((err) => {
          console.log("error while fetching client details", err);
        });
      const Startdateformate =
        startdate.slice(8, 10) +
        "/" +
        startdate.slice(5, 7) +
        "/" +
        startdate.slice(0, 4);
      const enddateformate =
        enddate.slice(8, 10) +
        "/" +
        enddate.slice(5, 7) +
        "/" +
        enddate.slice(0, 4);
      if (Startdateformate && enddateformate) {
        axios
          .post(
            `${BASEURL}/getClientStatement`,
            {
              CLIENTCODE: Code,
              FROMDATE: Startdateformate,
              TODATE: enddateformate,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setInitialdata(response?.data);
            setIsloading(false);
          })
          .catch((err) => {
            console.log("error fetching Customer Statement", err);
            setIsloading(false);
          });
      }

      const enddateformat =
        enddate.slice(8, 10) +
        "/" +
        enddate.slice(5, 7) +
        "/" +
        enddate.slice(0, 4);
      if (enddateformat) {
        axios
          .post(
            `${BASEURL}/getClientAgeing`,
            {
              CLIENTCODE: Code,
              TODATE: enddateformat,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setZeroToThirty(response?.data[0]?.ZEROTHIRTYDAYS);
            setThirtyToSixty(response?.data[0]?.THIRTYSIXTYDAYS);
            setSixtyToNinty(response?.data[0]?.SIXTYNINETYDAYS);
            setAboveNinty(response?.data[0]?.ABOVENINETYDAYS);
            setShowMoreDetails(true);
          })
          .catch((error) => {
            setIsloading(false);
            console.log("error fetching client code details", error);
          });
      }
    } else {
      alert("End date must be greater than or equal to start date.");
      setEnddate(formattedToday);
    }
  }, [startdate, enddate]);

  useEffect(() => {
    // Update counts when component mounts
    const updatedCounts = {};
    let runningCount = 0;
    filteredData.forEach((item, index) => {
      const amount = parseFloat(item?.AMOUNT);
      runningCount =
        (count[index - 1] || 0) + (item?.DRCR === "DR" ? amount : -amount);
      updatedCounts[index] = runningCount;
    });
    setCount(updatedCounts);
  }, [filteredData]);

  const handleBackAndReload = () => {
    window.history.back();
  };

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <section className="block print:hidden">
        <div
          className="Unapp_payment"
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "sticky",
            top: "0",
            left: "0",
            zIndex: "500",
          }}
        >
          <h1 style={{ textAlign: "center" }} className="Titles">
            Tech23 Dashboards
          </h1>
          <div className="nav_1">
            <div className="back_btn">
              <button className="View" onClick={handleBackAndReload}>
                Back
              </button>
            </div>
          </div>
        </div>
        <div>
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Customer Statement
          </h2>
        </div>

        <div className="General_box">
          <div className="General_first">
            <div className="G_acc_code">
              <p style={{ marginTop: "10px" }}>
                <b>Search :</b>
              </p>
              <input
                type="text"
                style={{
                  width: "256px",
                  height: "30px",
                  outline: "none",
                  padding: "2px",
                  marginTop: "10px",
                }}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setShowMoreDetails(false);
                }}
              ></input>
            </div>
            <div className="G_Start_Date">
              <p>
                <b>Start Date:</b>
              </p>
              <input
                type="date"
                className="as_at"
                value={startdate?.split("T")[0]}
                onChange={(e) => {
                  setstartdate(e.target.value);
                  setShowMoreDetails(false);
                }}
              ></input>
            </div>
            <div className="G_End_Date">
              <p>
                <b>End Date:</b>
              </p>
              <input
                type="date"
                className="as_at"
                value={enddate?.split("T")[0]}
                onChange={(e) => {
                  setEnddate(e.target.value);
                  setShowMoreDetails(false);
                }}
              ></input>
            </div>
            <button
              className="button"
              style={{ marginTop: "10px", marginLeft: "20px" }}
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
          </div>
        </div>

        <div className="relative">
          <div className="table__pos__app w-[98%]">
            <table class="custom-table ">
              <thead class="header-cell">
                <tr>
                  <td className="text-left py-2 px-4">TRANSACTION TYPE</td>
                  <td className="text-left py-2 pl-8">DOC NO</td>
                  <td className="text-left py-2 px-4">REF NO</td>
                  <td className="text-left py-2 pl-10">DATE</td>
                  <td className="text-right py-2 pr-10">DR</td>
                  <td className="text-right py-2 pr-10">CR</td>
                  <td className="text-right py-2 pr-10">CUMULATIVE</td>
                </tr>
              </thead>

              {isloading ? (
                <LoadingScreen />
              ) : (
                <tbody>
                  {filteredData.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="table-row"
                        // onClick={() => {
                        //   handleRowClick(item?.DOCDATE);
                        // }}
                      >
                        <td className="text-left py-2 px-4">
                          {item.TRANSACTIONTYPE ? item.TRANSACTIONTYPE : "N/A"}
                        </td>
                        <td className="text-right py-2 pr-20">
                          {item.DOCNO ? item.DOCNO : "N/A"}
                        </td>
                        <td className="text-left py-2 px-4 w-40">
                          {item.REFNO ? item.REFNO : "N/A"}
                        </td>
                        <td className="text-left py-2 px-4">
                          {item.DOCDATE
                            ? moment(item.DOCDATE).format("DD/MM/YYYY")
                            : "N/A"}
                        </td>
                        <td className="text-right py-2 pr-10">
                          {item.DRCR === "DR"
                            ? item?.AMOUNT.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td className="text-right py-2 pr-10">
                          {item.DRCR === "CR"
                            ? item?.AMOUNT.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                        <td className="text-right py-2 pr-10">
                          {count[index] !== undefined
                            ? count[index].toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "N/A"}
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="bg-white hover:bg-gray-100 border-b border-gray-200 text-xs">
                    <td colSpan="4"></td>
                    <td className="text-right py-2 pr-10 font-extrabold">
                      {totalDR
                        ? totalDR.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </td>
                    <td className="text-right py-2 pr-10 font-extrabold">
                      {totalCR
                        ? totalCR.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </td>
                    <td className="text-right py-2 pr-10 font-extrabold">
                      {balance
                        ? balance.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="relative bottom-0 right-0 w-full shadow-lg z-10 pt-2 pb-2">
            {showmoredetails && (
              <div className="w-full h-auto flex justify-end mt-4 pr-4 gap-3">
                <div className="flex flex-col items-center">
                  <span>0-30 Days</span>
                  <input
                    className="w-32 h-7 text-right border-none bg-slate-400 pr-1"
                    value={
                      zerotothirty
                        ? zerotothirty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0
                    }
                    readOnly
                  ></input>
                </div>
                <div className="flex flex-col items-center">
                  <span>30-60 Days</span>
                  <input
                    className="w-32 h-7  text-right border-none bg-slate-400 pr-1"
                    value={
                      thirtytosixty
                        ? thirtytosixty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0
                    }
                    readOnly
                  ></input>
                </div>
                <div className="flex flex-col items-center">
                  <span>60-90 Days</span>
                  <input
                    className="w-32 h-7 text-right border-none bg-slate-400 pr-1"
                    value={
                      sixtytoninty
                        ? sixtytoninty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0
                    }
                    readOnly
                  ></input>
                </div>
                <div className="flex flex-col items-center">
                  <span>Above 90 Days</span>
                  <input
                    className="w-32 h-7 text-right border-none bg-slate-400 pr-1"
                    value={
                      aboveninty
                        ? aboveninty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0
                    }
                    readOnly
                  ></input>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Print */}
      <section className="hidden print:block">
        <div className="flex justify-between m-4">
          <div className="flex flex-col gap-2">
            {/* <div className="flex gap-4">
              <span className="font-semibold">Client Code :</span>
              <span>{clientCode}</span>
            </div> */}
            <div className="flex gap-2">
              <span>Client Name :</span>
              <span>{clientname}</span>
            </div>
            <div className="flex gap-2">
              <span>Credit Limit :</span>
              <span>
                {creditLimit
                  ? creditLimit.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""}
              </span>
            </div>
            <div className="flex gap-2">
              <span>Credit Days :</span>
              <span>{creditDays}</span>
            </div>
            <div className="flex gap-2">
              <span>Mobile No :</span>
              <span>{mobileno}</span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-red-500">{orgname}</span>
            <span>{address1}</span>
            <span>{address2}</span>
            <span>{address3}</span>
            <span>{city}</span>
            <span>{country}</span>
          </div>
        </div>
        <div className="flex gap-4 ml-4 mt-2 ">
          <span>Start Date : </span>
          <input type="date" value={startdate}></input>
          <span>End Date : </span>
          <input type="date" value={enddate}></input>
        </div>
        <div className="relative">
          <div className="w-full flex justify-center mt-4">
            <table className="table-auto w-[97%]">
              <thead className="top-0 bg-blue-500 text-white z-10">
                <tr>
                  <th className="text-left py-2 px-4">TRANSACTION TYPE</th>
                  <th className="text-left py-2 pl-8">DOC NO</th>
                  <th className="text-left py-2 px-4">REF NO</th>
                  <th className="text-left py-2 pl-10">DATE</th>
                  <th className="text-right py-2 pr-10">DR</th>
                  <th className="text-right py-2 pr-10">CR</th>
                  <th className="text-right py-2 pr-10">CUMULATIVE</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white hover:bg-gray-100 border-b border-gray-200 text-xs cursor-pointer"
                      // onClick={() => {
                      //   handleRowClick(item?.DOCDATE);
                      // }}
                    >
                      <td className="text-left py-2 px-4">
                        {item.TRANSACTIONTYPE ? item.TRANSACTIONTYPE : "N/A"}
                      </td>
                      <td className="text-right py-2 pr-20">
                        {item.DOCNO ? item.DOCNO : "N/A"}
                      </td>
                      <td className="text-left py-2 px-4 w-40">
                        {item.REFNO ? item.REFNO : "N/A"}
                      </td>
                      <td className="text-left py-2 px-4">
                        {item.DOCDATE
                          ? moment(item.DOCDATE).format("DD/MM/YYYY")
                          : "N/A"}
                      </td>
                      <td className="text-right py-2 pr-10">
                        {item.DRCR === "DR"
                          ? item?.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td className="text-right py-2 pr-10">
                        {item.DRCR === "CR"
                          ? item?.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0.0}
                      </td>
                      <td className="text-right py-2 pr-10">
                        {count[index] !== undefined
                          ? count[index].toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                    </tr>
                  );
                })}
                <tr className="bg-white hover:bg-gray-100 border-b border-gray-200 text-xs">
                  <td colSpan="4"></td>
                  <td className="text-right py-2 pr-10 font-extrabold">
                    {totalDR
                      ? totalDR.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </td>
                  <td className="text-right py-2 pr-10 font-extrabold">
                    {totalCR
                      ? totalCR.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </td>
                  <td className="text-right py-2 pr-10 font-extrabold">
                    {balance
                      ? balance.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-full shadow-lg z-10 pt-2 pb-2">
            {showmoredetails && (
              <div className="w-full h-auto flex justify-end mt-4 pr-4 gap-3">
                <div className="flex flex-col items-center">
                  <span>0-30 Days</span>
                  <input
                    className="w-32 h-7 text-right border-none bg-slate-400 pr-1"
                    value={
                      zerotothirty
                        ? zerotothirty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"
                    }
                    readOnly
                  ></input>
                </div>
                <div className="flex flex-col items-center">
                  <span>30-60 Days</span>
                  <input
                    className="w-32 h-7  text-right border-none bg-slate-400 pr-1"
                    value={
                      thirtytosixty
                        ? thirtytosixty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"
                    }
                    readOnly
                  ></input>
                </div>
                <div className="flex flex-col items-center">
                  <span>60-90 Days</span>
                  <input
                    className="w-32 h-7 text-right border-none bg-slate-400 pr-1"
                    value={
                      sixtytoninty
                        ? sixtytoninty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"
                    }
                    readOnly
                  ></input>
                </div>
                <div className="flex flex-col items-center">
                  <span>Above 90 Days</span>
                  <input
                    className="w-32 h-7 text-right border-none bg-slate-400 pr-1"
                    value={
                      aboveninty
                        ? aboveninty.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "N/A"
                    }
                    readOnly
                  ></input>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AnalyticsBasedOnCustomerStatement;
