import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import axios from "axios";
import moment from "moment";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "../../../../Components/Footer";
import "../../../../CSS/AnalyticsBasedOnCreditorsOutstanding.css";

function AnalyticsBasedOnCreditorsOutstandingTrading() {
  const [isloading, setIsloading] = useState(true);
  const [isLoading2, setLoading2] = useState(true);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [date, setDate] = useState(new Date().toISOString());
  const [codata, setCOdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
    setIsloading(false);
  }, [date]);

  const getDetails = () => {
    setLoading2(true);
    axios
      .post(
        `${BASEURL}/CreditorsOutstanding`,
        {
          date: moment(date).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setCOdata(response.data);
        setLoading2(false);
      })
      .catch((error) => {
        setLoading2(false);
        console.log("Error Fetching Creditor's Outstanding Data : ", error);
      });
  };

  function handleView(value1) {
    navigate("/AnalyticsBasedOnCustomerStatementTrading", {
      state: { value1, enddate: date },
    });
  }

  const ExcelData = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Creditors Outstanding");
    const rows = document.querySelectorAll(".table_1 tbody tr");

    const columnWidths = [15, 10, 35, 15, 10, 10]; // Adjust the values as needed

    // Set column widths for the worksheet
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    // Add headers
    const headerRow = worksheet.addRow([
      "VENDCODE",
      "VENDNAME",
      "CURRENCY",
      "AMOUNT",
    ]);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Add rows from the table
    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      worksheet.addRow(rowData);
    });

    // Create a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "CreditorsOutstanding.xlsx";
      link.click();
    });
  };

  const PdfData = () => {
    const doc = new jsPDF();

    doc.text("Creditors Outstanding", 10, 10);

    const columns = ["VENDCODE", "VENDNAME", "CURRENCY", "AMOUNT"];
    const data = codata.map((data) => [
      data.VENDCODE,
      data["VENDNAME "],
      data.CURRENCY,
      data.AMOUNT,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
      startY: 20,
      showHead: "firstPage",
    });

    doc.save("CreditorsOutstanding.pdf");
  };

  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      {" "}
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/FinancialTradingDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Creditors Outstanding
        </h2>
      </div>
      <div className="creditor_box">
        <div className="creditors_first">
          <div className="creditors_from_date">
            <p>
              <b>Date:</b>
            </p>
            <input
              className="as_at"
              value={date.split("T")[0]}
              type="date"
              onChange={(e) => {
                if (e.target.value === "") {
                  alert("Please select a valid date");
                } else {
                  setDate(e.target.value);
                }
              }}
            ></input>
          </div>
          <div className="creditor_search">
            <p>
              <b>Search:</b>
            </p>
            <input
              className="input_Like"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      {isLoading2 ? (
        <Loading2 />
      ) : (
        <>
          <div className="stock_icons">
            <div>
              <RiFileExcel2Line onClick={ExcelData} size={35} />
            </div>
            <div>
              <BsFileEarmarkPdf onClick={PdfData} size={35} />
            </div>
          </div>
          <aside className="aside">
            <div
              style={{
                width: "95%",

                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <table className="table_1">
                <thead className="thead_stock">
                  <tr className="tr_test1">
                    <td className="vend_code">VEND CODE</td>
                    <td className="vend_name">VEND NAME</td>
                    {/* <td>AMOUNTHARCURR</td> */}
                    <td className="curr">CURRENCY</td>
                    <td className="amount">AMOUNT</td>
                    <td style={{ fontWeight: "500", paddingLeft: "20px" }}>
                      ACTION
                    </td>
                  </tr>
                </thead>
                <tbody className="stock_tbody">
                  {codata
                    .filter((data) => {
                      const searchKeywords = searchQuery
                        .toLowerCase()
                        .split(" ");
                      return searchKeywords.every((keyword) =>
                        ["VENDCODE", "VENDNAME ", "CURRENCY"]
                          .map((key) => String(data[key]).toLowerCase())
                          .join(" ")
                          .includes(keyword)
                      );
                    })
                    .map((data) => {
                      return (
                        <tr className="tr_test2">
                          <td className="value_vend_code">{data.VENDCODE}</td>
                          <td style={{ width: "300px", padding: "10px" }}>
                            {data.VENDNAME}
                          </td>
                          {/* <td style={{ paddingLeft: "60px" }}>
                            {data.AMOUNTHARCURR.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td> */}
                          <td>{data.CURRENCY}</td>
                          <td>
                            {data.AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <button
                            className="button"
                            type="button"
                            onClick={() => handleView(data.VENDCODE)}
                          >
                            View
                          </button>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </aside>
        </>
      )}
      <Footer />
    </div>
  );
}

export default AnalyticsBasedOnCreditorsOutstandingTrading;
