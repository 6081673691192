import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "../../../../CSS/AnalyticsBasedOnPaymentmode.css";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";

import "../../../../CSS/ListofVehicles.css";
import { useNavigate } from "react-router-dom";
import MonthWiseIncome from "./MonthWiseIncome";
import MonthWiseIncomeforSelectedTruck from "./MonthWiseIncomeforSelectedTruck";
import RouteWiseIncome from "./RouteWiseIncome";
import ClientWiseIncome from "./ClientWiseIncome";
import ClientWiseIncomeSelectedClient from "./ClientWiseIncomeSelectedClient";
import TransportIncomeVsExpensesIncome from "./TransportIncomeVsExpensesIncome";
import RouteWiseIncomeSelectedRoute from "./RouteWiseIncomeSelectedRoute";
import TransportIncomeVsExpensesIncomeSelectedTruck from "./TransportIncomeVsExpensesIncomeSelectedTruck";
import TruckwiseIncomeVsExpenses from "./TruckwiseIncomeVsExpenses";
import MonthWiseIncomeforTruck from "./MonthWiseIncomeforTruck";
import TransportDashboardsNavbar from "../../../../Components/TransportDashboardsNavbar";
const TransportIncome = () => {
  const [selectedComponent, setSelectedComponent] = useState({
    value: "MonthWiseIncome",
    label: "Month Wise Income",
  });
  const options = [
    {
      value: "MonthWiseIncome",
      label: "Month Wise Income",
    },
    {
      value: "MonthWiseIncomeforTruck",
      label: "Month Wise Income for  Truck",
    },

    {
      value: "RouteWiseIncome",
      label: "Route Wise Income",
    },
    {
      value: "ClientWiseIncome",
      label: "Client  wise Income",
    },
    {
      value: "MonthWiseIncomeforSelectedTruck",
      label: "Month Wise Income for Selected Truck",
    },

    {
      value: "RouteWiseIncomeSelectedRoute",
      label: "Route Wise Income Selected Route",
    },
    {
      value: "ClientWiseIncomeSelectedClient",
      label: "Client  wise Income - Selected Client",
    },
    {
      value: "TransportIncomeVsExpensesIncome",
      label: "Transport Income Vs Expenses Income",
    },
    {
      value: "TransportIncomeVsExpensesIncomeSelectedTruck",
      label: "Transport Income Vs Expenses Income Selected Truck",
    },
    // {
    //   value: "TruckwiseIncomeVsExpenses",
    //   label: "Truckwise Income Vs Expenses",
    // },
  ];

  const handleChange = (selectedOption) => {
    setSelectedComponent(selectedOption);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    setisloading(false);
  }, []);

  if (isloading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TransportDashboardsNavbar />

      <header className="header__of__main__dashboard flex justify-between items-end px-3">
        <h2 className="header__of__page">Transport Income</h2>

        <div
          className="lg:w-[400px] text-sm w-[100%] mx-2 "
          style={{ zIndex: 999 }}
        >
          <Select
            value={selectedComponent}
            onChange={handleChange}
            options={options}
            placeholder="Select Component"
          />
        </div>
      </header>

      <div>
        {selectedComponent && selectedComponent.value === "MonthWiseIncome" && (
          <MonthWiseIncome />
        )}
        {selectedComponent &&
          selectedComponent.value === "MonthWiseIncomeforSelectedTruck" && (
            <MonthWiseIncomeforSelectedTruck />
          )}
        {selectedComponent && selectedComponent.value === "RouteWiseIncome" && (
          <RouteWiseIncome />
        )}
        {selectedComponent &&
          selectedComponent.value === "ClientWiseIncome" && (
            <ClientWiseIncome />
          )}
        {selectedComponent &&
          selectedComponent.value === "ClientWiseIncomeSelectedClient" && (
            <ClientWiseIncomeSelectedClient />
          )}
        {selectedComponent &&
          selectedComponent.value === "RouteWiseIncomeSelectedRoute" && (
            <RouteWiseIncomeSelectedRoute />
          )}
        {selectedComponent &&
          selectedComponent.value === "TransportIncomeVsExpensesIncome" && (
            <TransportIncomeVsExpensesIncome />
          )}
        {selectedComponent &&
          selectedComponent.value ===
            "TransportIncomeVsExpensesIncomeSelectedTruck" && (
            <TransportIncomeVsExpensesIncomeSelectedTruck />
          )}
        {selectedComponent &&
          selectedComponent.value === "TruckwiseIncomeVsExpenses" && (
            <TruckwiseIncomeVsExpenses />
          )}
        {selectedComponent &&
          selectedComponent.value === "MonthWiseIncomeforTruck" && (
            <MonthWiseIncomeforTruck />
          )}
      </div>
    </div>
  );
};

export default TransportIncome;
