import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Loading2 from "../../../../Components/Loading2";
import LoaderSmall from "../../../../Components/LoadingSmall";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const FuelConsumptionDriverWise = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading2, setLoading2] = useState(false);
  const [isLoading3, setLoading3] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");

  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [analyticsData, setanalyticsData] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [selVehicle, setSelVehicle] = useState("");
  useEffect(() => {
    getVehicleList(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, []);

  function getVehicleList() {
    setLoading3(true);
    axios
      .get(
        `${BASEURL}/driverName`,

        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setVehicleList(response.data);
        setLoading3(false);
        console.log(response.data);
      });
  }
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  async function fetchAnalysis() {
    const formattedFromDate = fromdate
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/");
    const formattedToDate = todate.split("T")[0].split("-").reverse().join("/");
    console.log(formattedFromDate);
    try {
      if (
        isValidDate(formattedFromDate) &&
        isValidDate(formattedToDate) &&
        selVehicle
      ) {
        setLoading2(true);
        const response = await axios.post(
          `${BASEURL}/fuelConsumptionDriverwise`,
          {
            STARTDATE: formattedFromDate,
            ENDDATE: formattedToDate,
            DRIVERNAME: selVehicle,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        console.log(response.data);
        setLoading2(false);
        setanalyticsData(response.data);
      }
    } catch (error) {
      console.error("Error fetching analysis data:", error);
      alert(
        "An error occurred while fetching analysis data. Please try again."
      );
    } finally {
      // Ensure loading state is updated
      setLoading2(false);
    }
  }

  useEffect(() => {
    fetchAnalysis(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [selVehicle, todate, fromdate]);

  // useEffect(fetchAnalysis(), [selVehicle, todate, fromdate]);
  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };
  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>
        </div>

        <div>
          <p className="mt-2  lg:mt-0 lg:ml-[100px]">Driver :</p>
        </div>

        <div className="select__cunsuption">
          {isLoading3 === true ? (
            <LoaderSmall />
          ) : (
            <Select
              className="route_select"
              onChange={(e) => {
                setSelVehicle(e.value);
              }}
              options={vehicleList.map((item) => ({
                label: item["EMPLOYEE NAME"],
                value: item["EMPLOYEE NAME"],
              }))}
            />
          )}
        </div>

        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>
      <div style={{ width: "95%", margin: "0 auto" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {analyticsData.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>

                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>
                {chartType === "bar" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "bar-chart", // Change the ID for the bar chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) =>
                          moment(item?.PERIOD).format("MMM YYYY")
                        ),
                        title: {
                          text: "Month", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "Amount", // Y-axis label
                          },
                        },
                        {
                          title: {
                            text: "Litres", // Y-axis label
                          },
                          opposite: true,
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for bar chart
                      },
                    }}
                    series={[
                      {
                        name: "Amount",
                        data: analyticsData.map((item) => item?.AMOUNT),
                      },
                      {
                        name: "Litres",
                        data: analyticsData.map((item) => item?.LITRES),
                      },
                    ]}
                    type="bar" // Change chart type to "bar"
                    height={300}
                  />
                )}
                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) =>
                          moment(item?.PERIOD).format(" MMM YYYY")
                        ),
                        title: {
                          text: "PERIOD", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "AMOUNT",
                        data: analyticsData.map((item) => item?.AMOUNT),
                      },
                      {
                        name: "LITRES",
                        data: analyticsData.map((item) => item?.LITRES),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
                {chartType === "pie" && (
                  <ReactApexChart
                    options={{
                      labels: analyticsData.map((item) =>
                        moment(item?.PERIOD).format(" MMM YYYY")
                      ),
                      title: {
                        text: "Pie Chart", // Title of the pie chart
                      },
                      dataLabels: {
                        enabled: true, // Enable data labels for pie chart
                      },
                      tooltip: {
                        enabled: true,
                        y: [
                          {
                            formatter: function (val, opts) {
                              let index = opts.dataPointIndex;
                              let name = "AMOUNT";
                              return name + ": " + val;
                            },
                          },
                        ],
                      },
                    }}
                    series={analyticsData.map((item) => item?.AMOUNT)}
                    type="pie" // Set chart type to "pie"
                    height={300}
                  />
                )}

                {chartType === "scatter" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "scatter-chart", // Change the ID for the scatter chart
                      },
                      xaxis: {
                        type: "datetime",
                        categories: analyticsData.map((item) =>
                          new Date(item?.PERIOD).getTime()
                        ), // Convert to milliseconds
                        title: {
                          text: "Months", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "AMOUNT", // Y-axis label
                          },
                        },
                        {
                          opposite: true,
                          title: {
                            text: "LITRES", // Y-axis label for the right side
                          },
                        },
                      ],
                      title: {
                        text: "Scatter Chart",
                      },
                    }}
                    series={[
                      {
                        name: "Amount",
                        data: analyticsData.map((item) => ({
                          x: new Date(item?.PERIOD).getTime(), // Convert to milliseconds
                          y: item?.AMOUNT,
                        })),
                      },
                      {
                        name: "Litres",
                        data: analyticsData.map((item) => ({
                          x: new Date(item?.PERIOD).getTime(), // Convert to milliseconds
                          y: item?.LITRES,
                        })),
                        yAxis: 1, // Assign this series to the right y-axis
                      },
                    ]}
                    type="scatter" // Set chart type to "scatter"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                {selVehicle && <p> No Data Available</p>}
                {!selVehicle && <p> Select Driver</p>}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FuelConsumptionDriverWise;
