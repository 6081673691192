import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../../Components/features/userSlice";
import { useDispatch } from "react-redux";
import Footer from "../../../../Components/Footer";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Components/features/userSlice";
import TradingAnalyticsNavbar from "../../../../Components/TradingAnalyticsNavbar";

function FinancialTradingDashboard() {
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };
  return (
    <div>
      <TradingAnalyticsNavbar />
      <div className="h2">
        <h1>Financial Dashboards</h1>
      </div>
      <div className="dashboard">
        {/* <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnNominalActivityTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">General Ledger</div>
          </div>
        </div> */}

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnTrialBalanceTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">Trial Balance</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnProfitandLossTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">Profit and Loss</div>
          </div>
        </div>

        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnBalanceSheetTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">Balance Sheet</div>
          </div>
        </div>

        {/* <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnCustomerStatementTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">Customer Outstanding</div>
          </div>
        </div> */}

        {/* <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnSupplierStatementTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">Supplier Outstanding</div>
          </div>
        </div> */}
        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnOutstandingDebtorsTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">Debtors Outstanding</div>
          </div>
        </div>
        <div
          className="card"
          onClick={() => {
            navigate("/AnalyticsBasedOnCreditorsOutstandingTrading");
          }}
        >
          <div className="card-content">
            <div className="card-title">Creditors Outstanding</div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default FinancialTradingDashboard;
