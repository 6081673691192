import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Loading2 from "../../../../Components/Loading2";
import { Link } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import Select from "react-select";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
import Plot from "react-plotly.js";
const ExpenseTypeAnalysisMonthwiseBreakdown = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading2, setLoading2] = useState(false);
  const [VehicleNo, setVehicleNo] = useState([]);
  const [selvehicleNo, setSelVehicleNo] = useState("");
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [analyticsData, setanalyticsData] = useState([]);
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };
  useEffect(() => {
    fetchAnalysis(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [fromdate, todate, selvehicleNo]);

  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  async function fetchAnalysis() {
    setLoading2(true);
    try {
      const formattedFromDate = fromdate
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      const formattedToDate = todate
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      console.log(formattedFromDate);

      if (isValidDate(formattedFromDate) && isValidDate(formattedToDate)) {
        const response = await axios.post(
          `${BASEURL}/expensetypeanalysismonthwisebreakdown `,
          {
            STARTDATE: formattedFromDate,
            ENDDATE: formattedToDate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        console.log(response);
        setLoading2(false);
        setanalyticsData(response.data);
      } else {
        //   alert("Select start and end date");
        setLoading2(false);
      }
    } catch (error) {
      console.error("Error fetching analysis data:", error);
      alert(
        "An error occurred while fetching analysis data. Please try again."
      );
    } finally {
      // Ensure loading state is updated
      setLoading2(false);
    }
  }
  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  useEffect(() => {
    fetchAnalysis(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [todate, fromdate]);

  const plotData = analyticsData.map((item) => ({
    x: [moment(item?.PERIOD).format(" MMM YYYY")],
    y: [item.COST],
    type: "bar",
    name: item?.["CHARGE TYPE"],
    text: `${moment(item?.PERIOD).format(" MMM YYYY")}<br>Amount: ${
      item.COST
    }<br>Charge : ${item?.["CHARGE TYPE"]}`,
    hoverinfo: "y+text", // Show y value and custom text on hover
  }));

  const layout = {
    width: 1200, // Set the width to your desired value

    scene: {
      xaxis: {
        title: "Date",
        type: "date",
        showgrid: true,
        linecolor: "black",
        linewidth: 3,
      },
      yaxis: {
        title: "Amount",
        showgrid: true,
        linecolor: "black",
        linewidth: 3,
      },
      // zaxis: { title: "Charge Type" },
    },
    margin: { l: 50, r: 50, b: 50, t: 50 },
  };
  const scatterPlotData = analyticsData.map((item) => ({
    x: [moment(item?.PERIOD).format(" MMM YYYY")],
    y: [item.COST],
    type: "bar3d",
    name: item?.["CHARGE TYPE"],
    text: `${moment(item?.PERIOD).format(" MMM YYYY")}<br>Amount: ${
      item.COST
    }<br>Charge : ${item?.["CHARGE TYPE"]}`,
    hoverinfo: "y+text",
  }));

  const scatterLayout = {
    width: 1200, // Set the width to your desired value

    scene: {
      xaxis: {
        title: "Date",
        type: "date",
        showgrid: true,
        linecolor: "black",
        linewidth: 3,
      },
      yaxis: {
        title: "Amount",
        showgrid: true,
        linecolor: "black",
        linewidth: 3,
      },
      // zaxis: { title: "Charge Type" },
    },
    margin: { l: 50, r: 50, b: 50, t: 50 },
  };
  const areaPlotData = analyticsData.map((item) => ({
    x: [moment(item?.PERIOD).format(" MMM YYYY")],
    y: [item.COST],
    type: "area",
    fill: "tozeroy",
    name: item?.["CHARGE TYPE"],
    text: `${moment(item?.PERIOD).format(" MMM YYYY")}<br>Amount: ${
      item.COST
    }<br>Charge : ${item?.["CHARGE TYPE"]}`,
    hoverinfo: "text",
  }));

  const areaLayout = {
    width: 1200,
    xaxis: {
      title: "Date",
      type: "date",
      showgrid: true,
      linecolor: "black",
      linewidth: 3,
    },
    yaxis: {
      title: "Amount",
      showgrid: true,
      linecolor: "black",
      linewidth: 3,
    },
    margin: { l: 50, r: 50, b: 50, t: 50 },
  };
  const piePlotData = [
    {
      labels: analyticsData.map((item) => item?.["CHARGE TYPE"]),
      values: analyticsData.map((item) => item.COST),
      type: "pie",
      textinfo: "label+value",
      hoverinfo: "label+value+percent",
    },
  ];

  const pieLayout = {
    width: 1200,
    margin: { l: 50, r: 50, b: 50, t: 50 },
  };

  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>
        </div>
        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>

      <div style={{ width: "95%", margin: "0 auto" }}>
        {isLoading2 ? (
          <Loading2 />
        ) : (
          <>
            {analyticsData.length > 0 ? (
              <div>
                <div className="chart__active__buttons">
                  <button
                    className={
                      chartType === "bar"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("bar")}
                  >
                    <IoBarChartOutline />
                  </button>
                  <button
                    className={
                      chartType === "pie"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("pie")}
                  >
                    <FaChartPie />
                  </button>

                  <button
                    className={
                      chartType === "scatter"
                        ? "active__chart"
                        : "not__active__chart"
                    }
                    onClick={() => handleButtonClick("scatter")}
                  >
                    <BiScatterChart />
                  </button>
                  <button
                    className={
                      chartType === "area"
                        ? "active__chart "
                        : "not__active__chart  "
                    }
                    onClick={() => handleButtonClick("area")}
                  >
                    <FaChartArea />
                  </button>
                </div>

                {chartType === "bar" && (
                  <div className=" overflow-auto">
                    {" "}
                    <Plot data={plotData} layout={layout} />{" "}
                  </div>
                )}

                {chartType === "pie" && (
                  <div className=" overflow-auto">
                    <Plot data={piePlotData} layout={pieLayout} />
                  </div>
                )}

                {chartType === "scatter" && (
                  <div className=" overflow-auto">
                    {" "}
                    <Plot data={scatterPlotData} layout={scatterLayout} />{" "}
                  </div>
                )}

                {/* {chartType === "area" && (
                  <Plot data={areaPlotData} layout={areaLayout} />
                )} */}

                {chartType === "area" && (
                  <ReactApexChart
                    options={{
                      chart: {
                        id: "area-chart", // Change the ID for the area chart
                      },
                      xaxis: {
                        categories: analyticsData.map((item) =>
                          moment(item?.PERIOD).format("MMM YYYY")
                        ),
                        title: {
                          text: "Month", // X-axis label
                        },
                      },
                      yaxis: [
                        {
                          title: {
                            text: "COST", // Y-axis label
                          },
                        },
                      ],
                      dataLabels: {
                        enabled: false, // Disable the data labels for area chart
                      },
                    }}
                    series={[
                      {
                        name: "COST",
                        data: analyticsData.map((item) => item?.COST),
                      },
                    ]}
                    type="area" // Change chart type to "area"
                    height={300}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  marginTop: "50px",
                  textDecoration: "underline",
                }}
              >
                <p> No Data Available</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ExpenseTypeAnalysisMonthwiseBreakdown;
// <ReactApexChart
//   options={{
//     chart: {
//       id: "bar-chart",
//       toolbar: {
//         show: true,
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: "55%",
//         endingShape: "rounded",
//         distributed: false, // Distribute the bars evenly along the x-axis
//       },
//     },
//     xaxis: {
//       type: "category",
//       categories: analyticsData.map((item) =>
//         moment(item?.PERIOD).format(" MMM YYYY")
//       ),
//       title: {
//         text: "Period",
//       },
//     },
//     yaxis: {
//       title: {
//         text: "COST",
//       },
//     },
//     zaxis: {
//       title: {
//         text: "CHARGE TYPE",
//       },
//     },
//     dataLabels: {
//       enabled: true, // Enable dataLabels to display z-axis values on bars
//       formatter: function (val) {
//         return val.z; // Display the z-axis value (CHARGE TYPE)
//       },
//       style: {
//         fontSize: "12px",
//         colors: ["#000000"], // Color of the data labels
//       },
//     },
//   }}
//   series={[
//     {
//       name: "Total Cost",
//       data: analyticsData.map((item) => ({
//         x: item?.PERIOD,
//         y: item?.COST,
//         z: item?.["CHARGE TYPE"],
//       })),
//     },
//   ]}
//   type="bar"
//   height={300}
// />
