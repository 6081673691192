import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import "../../../../CSS/PurchasesDashboard.css";
import Loading2 from "../../../../Components/Loading2";
import { Link } from "react-router-dom";
import { FaChartPie } from "react-icons/fa";
import { AiFillPrinter } from "react-icons/ai";

import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const ProductTotalQuantitiesProduced = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading2, setLoading2] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const [analyticsData, setanalyticsData] = useState([]);
  const [chartType, setChartType] = useState("bar");
  const [activeDates, setActiveDates] = useState(false);
  const handleButtonClick = (type) => {
    setChartType(type);
  };

  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [dateRange, setDateRange] = useState([
    // Define dateRange
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useEffect(() => {
    if (selectedDateRange) {
      handleDateFilterChange(selectedDateRange);
    }
  }, [selectedDateRange]);

  useEffect(() => {
    fetchAnalysis(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [fromdate, todate]);

  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  async function fetchAnalysis() {
    setLoading2(true);
    try {
      const formattedFromDate = fromdate
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      const formattedToDate = todate
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
      console.log(formattedFromDate);
      console.log(formattedToDate);
      if (isValidDate(formattedFromDate) && isValidDate(formattedToDate)) {
        const response = await axios.post(
          `${BASEURL}/reportforproductandtotalqty  `,
          {
            STARTDATE: formattedFromDate,
            ENDDATE: formattedToDate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        console.log(response.data, "reportforproductandtotalqty");
        setLoading2(false);
        setanalyticsData(response.data);
      } else {
        // alert("Select start and end date");
        setLoading2(false);
      }
    } catch (error) {
      console.error("Error fetching analysis data:", error);
      alert(
        "An error occurred while fetching analysis data. Please try again."
      );
    } finally {
      // Ensure loading state is updated
      setLoading2(false);
    }
  }

  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };

  return (
    <div ref={filterRef}>
      <main className="hide__when__print">
        <div className="align__date_1 lg:flex justify-between">
          <div
            onClick={() => setActiveDates(!activeDates)}
            className="cursor-pointer flex items-center gap-2 "
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <span className="bg-white inline-block px-1 rounded border-1px border-solid">
              {fromdate.slice(8, 10) +
                "-" +
                fromdate.slice(5, 7) +
                "-" +
                fromdate.slice(0, 4)}
            </span>{" "}
            -{" "}
            <span className="bg-white inline-block px-1 rounded">
              {todate.slice(8, 10) +
                "-" +
                todate.slice(5, 7) +
                "-" +
                todate.slice(0, 4)}
            </span>
          </div>
          <div className="w-[200px]">
            {" "}
            {/* <Select options={options} onChange={handleDateFilterChange} /> */}
          </div>

          {activeDates && (
            <div className="date__custom">
              {/* Date filter buttons */}
              <div className="flex justify-between">
                <h1>Recommended</h1>
              </div>
              <button onClick={() => handleDateFilterChange("last3days")}>
                Last 3 Days
              </button>
              <button onClick={() => handleDateFilterChange("last7days")}>
                Last 7 Days
              </button>

              <button onClick={() => handleDateFilterChange("last30days")}>
                Last 30 Days
              </button>
              <h1>Calendar months</h1>
              <button onClick={() => handleDateFilterChange("lastMonth")}>
                Last Month
              </button>
              <button onClick={() => handleDateFilterChange("lastQuarter")}>
                Last Quarter
              </button>
              <button onClick={() => handleDateFilterChange("lastYear")}>
                Last Year
              </button>
              <h1>Custom date</h1>
              <main className="flex mx-1 gap-3">
                <div className="">
                  <div>
                    <p>Start Date :</p>
                  </div>
                  <div>
                    <input
                      value={fromdate.split("T")[0]}
                      type="date"
                      onClick={() => setActiveDates(true)}
                      onChange={(e) => {
                        setFromdate(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="">
                  <div>
                    <p>End Date :</p>
                  </div>
                  <div>
                    <input
                      value={todate.split("T")[0]}
                      type="date"
                      onClick={() => setActiveDates(true)}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          alert("Please select a valid todate");
                        } else {
                          setTodate(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </main>
            </div>
          )}
          <button
            className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 "
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
        <div style={{ width: "95%", margin: "0 auto" }}>
          {isLoading2 ? (
            <Loading2 />
          ) : (
            <div>
              <div className="  overflow-auto m-[10px]  p-[20px] lg:w-[55%]">
                <table className="table_1" style={{ fontSize: "12px" }}>
                  <thead>
                    <tr style={{ fontWeight: "400" }}>
                      <td>ITEM NAME</td>
                      <td className="text-right"> QUANTITY&nbsp;PRODUCED </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(analyticsData) &&
                    analyticsData.length > 0 ? (
                      analyticsData.map((item, index) => {
                        return (
                          <tr key={index} className="table-row">
                            <td>{item?.ITEMNAME ? item?.ITEMNAME : "N/A"} </td>
                            <td className="text-right">
                              {item?.QTYPRODUCED.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </main>
      <div className="toshow__Print">
        <section className="relative my-[2%] mx-[50px] w-[705px] h-[1000px] p-[20px] text-[10px]">
          <main>
            <div className="text-center underline text-xl font-bold color text-blue-900">
              <h1>Production Cost-Product Total Quantities Produced </h1>
            </div>
            <p className="flex justify-between mx-[0px] my-[14px] text-[12px]">
              <div className="po__print__Header">
                Date range -
                <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                  {fromdate.slice(8, 10) +
                    "-" +
                    fromdate.slice(5, 7) +
                    "-" +
                    fromdate.slice(0, 4)}
                </span>{" "}
                -{" "}
                <span className="bg-white inline-block px-1 rounded">
                  {todate.slice(8, 10) +
                    "-" +
                    todate.slice(5, 7) +
                    "-" +
                    todate.slice(0, 4)}
                </span>
              </div>
            </p>
          </main>

          <aside className="">
            <table class="table-print">
              <tr style={{ fontWeight: "400" }}>
                <td>ITEM NAME</td>
                <td className="text-right"> QUANTITY&nbsp;PRODUCED </td>
              </tr>
              {analyticsData &&
                analyticsData.length > 0 &&
                Array.isArray(analyticsData) &&
                analyticsData.map((item, index) => (
                  <tr key={index} className="table-row">
                    <td>{item?.ITEMNAME ? item?.ITEMNAME : "N/A"} </td>
                    <td className="text-right">
                      {item?.QTYPRODUCED.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))}
            </table>
          </aside>
        </section>
      </div>
    </div>
  );
};

export default ProductTotalQuantitiesProduced;
