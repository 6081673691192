import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Footer from "../../../../Components/Footer";
function AnalyticsBasedOnSupplierStatementTrading() {
  return (
    <div>
      <div
        className="Unapp_payment"
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: "0",
          left: "0",
          zIndex: "500",
        }}
      >
        <h1 style={{ textAlign: "center" }} className="Titles">
          Tech23 Dashboards
        </h1>
        <div className="nav_1">
          <div className="back_btn">
            {" "}
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/FinancialTradingDashboard"}
            >
              <button className="View">Back</button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <h2>Customer Statement View</h2>
      </div>
      <div>
        <input type="radio"></input>
        <p>Debtor</p>
        <input type="radio"></input>
        <p>Creditor</p>
        <p>Credit Limit:</p>
        <p>Credit Days:</p>
        <p>Salesman</p>
        <button>View </button>
      </div>
      <div>
        <p>Supplier:</p>
        <Select className="select_sort_by" options={[]} />
        <p> Account Code:</p>
        <Select className="select_sort_by" options={[]} />
        <button>Actvity</button>
        <input type="checkbox"></input>
        <p>View Outstanding only</p>

        <button>Print </button>
      </div>
      <div>
        <p>Start Date:</p>
        <input type="Date"></input>
        <p>End Date:</p>
        <input type="Date"></input>
        <p>Currency</p>
        <input type="text"></input>
        <button>Print Service</button>
      </div>
      <aside>
        <div>
          <table>
            <thead>
              <tr>
                <td>SNo</td>
                <td>TRANSACTION</td>
                <td>DOC NO</td>
                <td>REF NO</td>
                <td>DATE</td>
                <td>DR</td>
                <td>CR</td>

                <td>CURRENCY</td>

                <td>CUMMULATIVE</td>
              </tr>
            </thead>
          </table>
        </div>
      </aside>
      <aside>
        <div>
          <table>
            <thead>
              <tr>
                <td>SNo</td>
                <td>LEDGER TYPE</td>
                <td>LEDGER</td>
                <td>DR/CR</td>
                <td>AMOUNT</td>
              </tr>
            </thead>
          </table>
        </div>
      </aside>
      <div>
        <p>Total Dr:</p>
        <input type="text"></input>
        <p>Total Cr:</p>
        <input type="text"></input>
        <p>Balance Dr:</p>
        <input type="text"></input>
      </div>
      <div>
        <p>0-30 Days:</p>
        <input type="text"></input>
        <button>View Details</button>
        <p>30-60 Days:</p>
        <input type="text"></input>
        <button>View Details</button>
        <p>60-90 Days:</p>
        <input type="text"></input>
        <button>View Details</button>
        <p>Above 90 Days:</p>
        <input type="text"></input>
        <button>View Details</button>
      </div>
      <div>
        <button>Payments for Invoice</button>
        <p>Total Outstanding</p>
        <input type="text"></input>
      </div>
      <Footer />
    </div>
  );
}

export default AnalyticsBasedOnSupplierStatementTrading;
