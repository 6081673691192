import React, { useState, useEffect } from "react";
import AllPagesMasterNavbar from "../../Components/MasterNavbar/AllPagesMasterNavbar";
import axios from "axios";
import moment from "moment";
import LoadingScreen from "../../Components/LoadingScreen";
import Select from "react-select";

const DEBTCOLLECTION = () => {
  const [isloading, setIsloading] = useState(true);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [date, setDate] = useState(new Date().toISOString());
  const [data, setData] = useState([]);
  const [selCustomer, setSelCustomer] = useState("");
  const [customersList, setCustomersList] = useState([]);
  const [selCurrency, setSelCurrency] = useState("");
  const [search, setSearch] = useState("");
  const [totalDebt, setTotalDebt] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [selContacted, setSelContacted] = useState("");
  const [transDate, setTransDate] = useState(new Date().toISOString());
  const [selFeedback, setSelFeedBack] = useState("");
  const [followOnDate, setFollowOnDate] = useState(new Date().toISOString());
  const [description, setDescription] = useState("");
  const [paymentCollected, setPaymentCollected] = useState(false);
  const [amountCollected, setAmountCollected] = useState(0);
  const [clientAddress, setClientAddress] = useState("");
  const [outstanding, setOutstanding] = useState("");
  const [email, setEmail] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const filteredData = data.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    // const sDate = item.BLENDINGDATE;
    // const eDate = item.BLENDINGDATE;

    if (searchData.length === 0 && !selCustomer) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "ITEMCODE",
        "MAINNAME",
        "CATEGORY",
        "SUBCATEGORY",
        "SPECIFICATIONS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const customerMatch = !selCustomer || item.CUSTOMER === selCustomer;
    return textMatch && customerMatch;
  });

  useEffect(() => {
    let totalDebt = 0;
    filteredData.forEach((item) => {
      totalDebt += parseFloat(item.AMOUNT);
    });
    setTotalDebt(totalDebt);
  }, [filteredData]);

  useEffect(() => {
    getClientList();
  }, []);
  useEffect(() => {
    getClientList();
  }, []);

  useEffect(() => {
    getDetails();
  }, [date]);

  const getClientList = () => {
    axios
      .get(`${BASEURL}/getClientList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setCustomersList(response.data);
      })
      .catch((error) => {
        setIsloading(false);
        console.log("Error Fetching Customers Data : ", error);
      });
  };

  const getDetails = () => {
    axios
      .post(
        `${BASEURL}/DebtorsOutstanding`,
        {
          date: moment(date).format("DD/MM/YYYY"),
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setData(response?.data);
        console.log(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        console.log("Error Fetching Debtor's Outstanding Data : ", error);
      });
  };

  //  save the data
  const saveData = async () => {
    try {
      const response = await axios.post(
        `${BASEURL}/globalSpHandler?spname=1`, // Replace with your actual endpoint
        {
          transactionId: transactionId,
          selCustomer: selCustomer,
          selCurrency: selCurrency,
          transDate: transDate,
          selContacted: selContacted,
          selFeedback: selFeedback,
          followOnDate: followOnDate,
          description: description,
          paymentCollected: paymentCollected,
          amountCollected: amountCollected,
          clientAddress: clientAddress,
          outstanding: outstanding,
          email: email,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error saving debtor's outstanding data:", error);
    }
  };

  useEffect(() => {
    if (paymentCollected) {
      setAmountCollected(0); // Reset amountCollected when paymentCollected is true
    }
  }, [paymentCollected]);

  if (isloading) {
    return <LoadingScreen />;
  }

  const handleRowClick = (rowData) => {
    setTransactionId(rowData["TRANSACTION ID"]);
    setSelCustomer(rowData["ACCOUNT NAME "]);
    setSelCurrency(rowData.CURRENCY);
    setTransDate(rowData["DATE"]);
    setSelContacted(rowData["CONTACTED"]);
    setSelFeedBack(rowData["FEEDBACK"]);
    setFollowOnDate(rowData["FOLLOW_ON_DATE"]);
    setDescription(rowData["DESCRIPTION"]);
    // You can also add any other data that you want to pre-fill.
  };
  console.log("line:126", selCustomer);

  return (
    <div className="bg-gray-100 min-h-screen p-1">
      <AllPagesMasterNavbar />
      {/* Header */}
      <div className="bg-white shadow-md p-4 flex justify-between items-center">
        <div className="flex justify-center items-center gap-5">
          <h1 className="text-lg font-bold">Debtors Outstanding</h1>
          <div className="flex justify-center items-center">
            <p className="mt-1">
              Total Amount:{" "}
              <span className="bg-yellow-200 p-1">
                {totalDebt?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </p>
          </div>
          <div className="flex justify-center items-center">
            <label className="mr-2">Customer Name:</label>
            <Select
              className="w-full"
              onChange={(e) => {
                setSelCustomer(e.value);
              }}
              value={
                selCustomer
                  ? { label: selCustomer, value: selCustomer }
                  : { label: "Select Customer", value: "" }
              }
              options={[
                { value: "", label: "Select Customer" },
                ...customersList.map((item) => ({
                  value: item["CLIENT NAME"],
                  label: item["CLIENT NAME"],
                })),
              ]}
            />
          </div>
          <div className="flex justify-center items-center">
            <label className="mr-2">As at Date:</label>
            <input
              type="date"
              className="border p-2 rounded"
              value={date?.split("T")[0]}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row mt-4 gap-4">
        {/* Sidebar */}
        <div className="w-full lg:w-1/3 bg-gray-200 p-4 rounded shadow-md">
          <label className="block font-semibold mb-2">Customer Name:</label>
          <input
            type="text"
            className="block w-full border p-2 rounded mb-4"
            placeholder="Enter name"
          />
          <button className="bg-green-500 text-white w-full py-2 rounded mb-4">
            View or Refresh
          </button>

          {/* Table Section */}
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-lg font-semibold mb-4">Outstanding Details</h2>
            <div className="h-96 overflow-x-auto">
              <table className="table_1">
                <thead className="thead_stock">
                  <tr className="tr_test1">
                    <td>ACCOUNT CODE</td>
                    <td>ACCOUNT NAME</td>
                    <td>ACCOUNT DEALER</td>
                    <td className="foreign_curr">AMOUNT IN FOREIGN CURRENCY</td>
                    <td>CURRENCY</td>
                    <td className="header_amount">AMOUNT</td>
                  </tr>
                </thead>
                <tbody className="stock_tbody">
                  {filteredData?.map((data, index) => {
                    return (
                      <tr
                        className="tr_test2"
                        key={index}
                        onClick={() => handleRowClick(data)}
                      >
                        <td style={{ width: "147px", paddingLeft: "10px" }}>
                          {data["ACCOUNT CODE"]}
                        </td>
                        <td style={{ padding: "10px", width: "325px" }}>
                          {data["ACCOUNT NAME "]}
                        </td>
                        <td style={{ width: "172px", paddingLeft: "10px" }}>
                          {data["ACCOUNT DEALER"]}
                        </td>
                        <td
                          style={{
                            width: "212px",
                            textAlign: "right",
                            paddingRight: "55px",
                          }}
                        >
                          {data["AMOUNT IN FOREIGN CURR"] != null
                            ? data["AMOUNT IN FOREIGN CURR"].toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0.0}
                        </td>
                        <td style={{ width: "140px", paddingLeft: "10px" }}>
                          {data.CURRENCY}
                        </td>
                        <td
                          style={{
                            width: "130px",
                            textAlign: "right",
                            paddingRight: "50px",
                          }}
                        >
                          {data.AMOUNT != null
                            ? data.AMOUNT.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0.0}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-2/3 bg-white shadow-md rounded p-4">
          <h2 className="bg-green-500 text-white text-center py-2 rounded">
            Follow-up Selected Client
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div>
              <label>Client Name:</label>
              <input
                type="text"
                className="border w-full p-1 rounded"
                value={selCustomer}
                onChange={(e) => setSelCustomer(e.target.value)}
              />
            </div>
            <div>
              <label>Address of Client:</label>
              <input
                type="text"
                className="border w-full p-1 rounded"
                value={clientAddress}
                onChange={(e) => setClientAddress(e.target.value)}
              />
            </div>
            <div>
              <label>Outstanding:</label>
              <input
                type="text"
                className="border w-full p-1 rounded"
                value={outstanding}
                onChange={(e) => setOutstanding(e.target.value)}
              />
            </div>
            <div>
              <label>Email Address:</label>
              <input
                type="email"
                className="border w-full p-1 rounded"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          {/* Follow-up Details */}

          <h3 className="bg-green-500 text-white text-center py-2 rounded mt-6">
            Follow-up Details
          </h3>
          {/* Line 1 */}
          <div className="flex justify-between mt-2">
            <div className="flex gap-2">
              <label>Transaction ID:</label>
              <input
                type="text"
                className="border w-20 h-8 rounded"
                value={transactionId}
                readOnly
              />
            </div>
            <div className="flex gap-2">
              <label>Contacted:</label>
              <Select
                className="w-full"
                onChange={(e) => {
                  selContacted(e.value);
                }}
                value={
                  selContacted
                    ? { label: selContacted, value: selContacted }
                    : { label: "Select Contacted", value: "" }
                }
                options={[
                  { value: "", label: "Select Contacted" },
                  { value: "ACCOUNTANT", label: "ACCOUNTANT" },
                  { value: "FC", label: "FC" },
                  { value: "DIRECTOR", label: "DIRECTOR" },
                  { value: "PROCUREMENT", label: "PROCUREMENT" },
                  { value: "MANAGER", label: "MANAGER" },
                  { value: "SALESMAN", label: "SALESMAN" },
                  { value: "CEO", label: "CEO" },
                ]}
              />
            </div>
            <div className="flex gap-2">
              <label>Date:</label>
              <input
                type="date"
                className="border rounded"
                value={transDate?.split("T")[0]}
                onChange={(e) => setTransDate(e.target.value)}
              />
            </div>
          </div>
          {/* Line 2 */}
          <div className="flex mt-2 gap-3">
            <label>Feedback:</label>
            <Select
              className="w-full ml-9"
              onChange={(e) => {
                setSelFeedBack(e.value);
              }}
              value={
                selFeedback
                  ? { label: selFeedback, value: selFeedback }
                  : { label: "Select Feedback", value: "" }
              }
              options={[
                { value: "", label: "Select Feedback" },
                {
                  value: "Didnt pick the phone",
                  label: "Didnt pick the phone",
                },
                {
                  value:
                    "Asked to call later because authority is not available",
                  label:
                    "Asked to call later because authority is not available",
                },
                {
                  value: "Asked to come and collect in a given date",
                  label: "Asked to come and collect in a given date",
                },
                {
                  value: "Asked for a statement of Accounts",
                  label: "Asked for a statement of Accounts",
                },
                {
                  value:
                    "Cash flow doesnt allow the client to pay and request for more time(unspecified)",
                  label:
                    "Cash flow doesnt allow the client to pay and request for more time(unspecified)",
                },
              ]}
            />
          </div>
          {/* Line 3 */}
          <div className="mt-2 flex">
            <div className="flex gap-2">
              <label>To follow on:</label>
              <input
                type="date"
                className="border rounded ml-5"
                value={followOnDate?.split("T")[0]}
                onChange={(e) => setFollowOnDate(e.target.value)}
              />
            </div>
          </div>

          {/* Line 4  */}
          <div className="mt-2 flex">
            <div className="flex gap-2">
              <label>Description:</label>
              <textarea
                className="border w-[700px] rounded ml-6"
                rows="3"
                value={description} // Controlled component
                onChange={(e) => setDescription(e.target.value)} // Update state on change
              ></textarea>
            </div>
          </div>

          {/* Line 5 */}
          <div className="mt-2 flex justify-between">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="payment"
                className="mr-2"
                checked={paymentCollected} // Controlled component
                onChange={(e) => setPaymentCollected(e.target.checked)} // Update state on change
              />
              <label htmlFor="payment">
                Payment collected for this follow-up
              </label>
            </div>
            <div className="flex gap-2">
              <label>Amount Collected:</label>
              <input
                type="number"
                className="border w-32  rounded"
                value={amountCollected}
                onChange={(e) => setAmountCollected(e.target.value)}
              />
            </div>
            <button
              className="bg-blue-500 text-white px-3 rounded"
              onClick={() => setShowDialog(true)} // Show the dialog when clicked
            >
              Save
            </button>
            {showDialog && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h3 className="text-xl mb-4">
                    Are you sure you want to save?
                  </h3>
                  <div className="flex justify-end">
                    <button
                      className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                      onClick={() => setShowDialog(false)} // Close the dialog
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                      onClick={() => {
                        saveData(); // Call the saveData function
                        setShowDialog(false); // Close the dialog
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DEBTCOLLECTION;
