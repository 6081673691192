import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "../../../../CSS/AnalyticsBasedOnClientAndItem.css";
import ReactApexChart from "react-apexcharts";
import LoadingScreen from "../../../../Components/LoadingScreen";
import Loading2 from "../../../../Components/Loading2";
import Footer from "../../../../Components/Footer";
import { FaChartPie } from "react-icons/fa";
import { IoBarChartOutline } from "react-icons/io5";
import { BiScatterChart } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { FaChartArea } from "react-icons/fa";
const PurchasesSelectedSupplierPeriodWise = () => {
  const [isLoading2, setLoading2] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [data, setData] = useState([]);
  const [VehicleNo, setVehicleNo] = useState([]);
  const [selvehicleNo, setSelVehicleNo] = useState("");
  const [fromdate, setFromdate] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [todate, setTodate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  // Options for the select dropdown
  const industryOptions = [
    { value: "", label: "All" }, // Include "All" option to reset filter
    { value: "TRADING", label: "TRADING" },
    { value: "TRANSPORT", label: "TRANSPORT" },
  ];

  // if (isloading) {
  //   return <LoadingScreen />;
  // }
  useEffect(() => {
    getVehicleNo();
    AnalyzeBasedOnClient();
    setisloading(false);
  }, [fromdate, todate, selvehicleNo]);
  function isValidDate(dateString) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Matches DD/MM/YYYY format

    if (!dateRegex.test(dateString)) {
      return false; // Format is invalid
    }

    const [day, month, year] = dateString.split("/").map(Number);

    // Year must be greater than 1900
    if (year < 1900) {
      return false;
    }

    // Month must be between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }

    // Validate day for the given month and year
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    return true;
  }
  const AnalyzeBasedOnClient = () => {
    const startDatePass =
      fromdate.slice(8, 10) +
      "/" +
      fromdate.slice(5, 7) +
      "/" +
      fromdate.slice(0, 4);
    const endDatePass =
      todate.slice(8, 10) + "/" + todate.slice(5, 7) + "/" + todate.slice(0, 4);
    console.log("startDatePass", startDatePass, "endDatePass", endDatePass);
    console.log(selvehicleNo);
    if (isValidDate(startDatePass) && isValidDate(endDatePass)) {
      setLoading2(true);
      axios
        .post(
          `${BASEURL}/purchasesforselectedSupplierperiodwise`,
          {
            STARTDATE: startDatePass,
            ENDDATE: endDatePass,
            // SUPPLIERNAME: selvehicleNo,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setLoading2(false);
          setData(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          setLoading2(false);
          console.log(error);
        });
    }
  };

  const getVehicleNo = () => {
    axios
      .get(`${BASEURL}/getSupplierList`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicleNo(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching VehicleNo:", error);
      });
  };
  const [chartType, setChartType] = useState("bar");

  const handleButtonClick = (type) => {
    setChartType(type);
  };

  const [activeDates, setActiveDates] = useState(false);
  const handleDateFilterChange = (filter) => {
    const currentDate = new Date();
    let startDate = null;
    let endDate = null;
    setActiveDates(false);

    switch (filter) {
      case "last7days":
        startDate = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last3days":
        startDate = new Date(
          currentDate.getTime() - 3 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "last30days":
        startDate = new Date(
          currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
        ).toISOString();
        endDate = currentDate.toISOString();
        break;
      case "lastMonth":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastQuarter":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          2
        ).toISOString();
        endDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).toISOString();
        break;
      case "lastYear":
        const previousYear = currentDate.getFullYear() - 1;
        startDate = new Date(previousYear, 0, 2).toISOString(); // January 1st of the previous year
        endDate = new Date(previousYear, 12, 1).toISOString(); // December 31st of the previous year
        break;

      default:
        break;
    }

    setFromdate(startDate);
    setTodate(endDate);
  };

  const filterRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setActiveDates(false);
    }
  };
  useEffect(() => {
    AnalyzeBasedOnClient(); // Call fetchAnalysis when component mounts or when fromdate or todate changes
  }, [selvehicleNo, todate, fromdate]);
  const filteredData = data.filter((item) => {
    const industryMatch =
      !selectedIndustry ||
      selectedIndustry.value === "" ||
      item?.INDUSTRY === selectedIndustry.value;

    return industryMatch;
  });
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <div ref={filterRef}>
      <div className="align__date_1">
        <div className="cursor-pointer lg:flex items-center gap-2 w-">
          <div
            className="flex gap-3"
            onClick={() => setActiveDates(!activeDates)}
          >
            <h2 className="text-2xl">
              {" "}
              <BsCalendar2Date />
            </h2>
            <div>
              <span className="bg-white inline-block px-1 rounded border-1px border-solid">
                {fromdate.slice(8, 10) +
                  "-" +
                  fromdate.slice(5, 7) +
                  "-" +
                  fromdate.slice(0, 4)}
              </span>{" "}
              -{" "}
              <span className="bg-white inline-block px-1 rounded">
                {todate.slice(8, 10) +
                  "-" +
                  todate.slice(5, 7) +
                  "-" +
                  todate.slice(0, 4)}
              </span>
            </div>
          </div>

          {/* <aside>
            <p className="mt-2  lg:mt-0 lg:ml-[100px]">Supplier Name </p>
          </aside> */}
          {/* <div>
            <Select
              className=" text-sm lg:w-[400px]"
              onChange={(e) => {
                setSelVehicleNo(e.value);
              }}
              value={
                selvehicleNo
                  ? { label: selvehicleNo, value: selvehicleNo }
                  : { label: "Select Supplier Name", value: "" }
              }
              options={[
                { value: "", label: "" },
                ...VehicleNo.map((item) => ({
                  value: item["VENDOR NAME"],
                  label: item["VENDOR NAME"],
                })),
              ]}
            />
          </div> */}
          {/* <p className="mt-2  lg:mt-0  lg:ml-5"> Industry &nbsp;</p>
          <div>
            <Select
              options={industryOptions}
              value={selectedIndustry}
              onChange={setSelectedIndustry}
              placeholder="Select Industry"
              isClearable
              className="w-[200px]"
            />
          </div> */}
        </div>
        {activeDates && (
          <div className="date__custom">
            {/* Date filter buttons */}
            <div className="flex justify-between">
              <h1>Recommended</h1>
            </div>
            <button onClick={() => handleDateFilterChange("last3days")}>
              Last 3 Days
            </button>
            <button onClick={() => handleDateFilterChange("last7days")}>
              Last 7 Days
            </button>

            <button onClick={() => handleDateFilterChange("last30days")}>
              Last 30 Days
            </button>
            <h1>Calendar months</h1>
            <button onClick={() => handleDateFilterChange("lastMonth")}>
              Last Month
            </button>
            <button onClick={() => handleDateFilterChange("lastQuarter")}>
              Last Quarter
            </button>
            <button onClick={() => handleDateFilterChange("lastYear")}>
              Last Year
            </button>
            <h1>Custom date</h1>
            <main className="flex mx-1 gap-3">
              <div className="">
                <div>
                  <p>Start Date :</p>
                </div>
                <div>
                  <input
                    value={fromdate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      setFromdate(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="">
                <div>
                  <p>End Date :</p>
                </div>
                <div>
                  <input
                    value={todate.split("T")[0]}
                    type="date"
                    onClick={() => setActiveDates(true)}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        alert("Please select a valid todate");
                      } else {
                        setTodate(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>

      <section className="flex mt-5 flex-wrap gap-3 justify-center lg:justify-between flex-col-reverse lg:flex-row">
        {filteredData.length > 0 && (
          <div className="overflow-y-auto max-h-[400px] px-4  lg:w-[25%] overflow-x-hidden ">
            <table className="custom-table text-sm overflow__y__ relative w-full ">
              <thead
                className="header-cell sticky top-[0px] left-[10px] text-[10px]"
                style={{ zIndex: 20 }}
              >
                <tr>
                  <td className="">PERIOD </td>
                  <td className="text-right">PURCHASES </td>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 &&
                  filteredData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        textTransform: "uppercase",
                      }}
                      className="text-[10px]"
                    >
                      {" "}
                      <td className=" ">
                        {item.PERIOD
                          ? moment(item?.PERIOD).format(" MMM YYYY")
                          : "0"}
                      </td>
                      <td className=" text-right">
                        {item["PURCHASES"] ? item["PURCHASES"] : "0"}
                      </td>{" "}
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr className="font-bold text-[10px]">
                  <td className="text-left">TOTAL</td>
                  <td className="text-right">
                    {filteredData.reduce(
                      (sum, item) => sum + (item["PURCHASES"] || 0),
                      0
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        <div
          style={{ margin: "0 auto" }}
          onClick={() => setActiveDates(false)}
          className="lg:w-[70%] w-full"
        >
          {" "}
          {isLoading2 ? (
            <Loading2 />
          ) : (
            <>
              {data && filteredData.length > 0 ? (
                <div>
                  {" "}
                  <div className="chart__active__buttons">
                    <button
                      className={
                        chartType === "bar"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("bar")}
                    >
                      <IoBarChartOutline />
                    </button>
                    <button
                      className={
                        chartType === "pie"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("pie")}
                    >
                      <FaChartPie />
                    </button>

                    <button
                      className={
                        chartType === "scatter"
                          ? "active__chart"
                          : "not__active__chart"
                      }
                      onClick={() => handleButtonClick("scatter")}
                    >
                      <BiScatterChart />
                    </button>
                    <button
                      className={
                        chartType === "area"
                          ? "active__chart "
                          : "not__active__chart  "
                      }
                      onClick={() => handleButtonClick("area")}
                    >
                      <FaChartArea />
                    </button>
                  </div>
                  {chartType === "bar" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "bar-chart",
                          toolbar: {
                            show: true,
                          },
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            columnWidth: "55%",
                            endingShape: "rounded",
                          },
                        },
                        xaxis: {
                          type: "category",
                          categories: filteredData.map((item) =>
                            moment(item?.PERIOD).format("D MMM YYYY")
                          ),
                          title: {
                            text: "DATE",
                          },
                        },
                        yaxis: {
                          title: {
                            text: "PURCHASES",
                          },
                        },

                        dataLabels: {
                          enabled: false,
                        },
                      }}
                      series={[
                        {
                          name: "Total Cost",
                          data: filteredData.map((item) => ({
                            x: item?.PERIOD,
                            y: item?.PURCHASES,
                          })),
                        },
                      ]}
                      type="bar"
                      height={300}
                    />
                  )}
                  {chartType === "area" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "area-chart", // Change the ID for the area chart
                        },
                        xaxis: {
                          categories: filteredData.map((item) =>
                            moment(item?.PERIOD).format("MMM YYYY")
                          ),
                          title: {
                            text: "Month", // X-axis label
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "Purchases", // Y-axis label
                            },
                          },
                        ],
                        dataLabels: {
                          enabled: false, // Disable the data labels for area chart
                        },
                      }}
                      series={[
                        {
                          name: "Purchases",
                          data: filteredData.map((item) => item?.PURCHASES),
                        },
                      ]}
                      type="area" // Change chart type to "area"
                      height={300}
                    />
                  )}
                  {chartType === "pie" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "pie-chart",
                        },
                        labels: filteredData.map((item) =>
                          moment(item?.PERIOD).format("d MMM YYYY")
                        ),
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 200,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                      }}
                      series={filteredData.map((item) => item?.PURCHASES)}
                      type="pie"
                      height={300}
                    />
                  )}
                  {chartType === "scatter" && (
                    <ReactApexChart
                      options={{
                        chart: {
                          id: "scatter-chart", // Change the ID for the scatter chart
                        },
                        xaxis: {
                          categories: filteredData.map((item) =>
                            moment(item?.PERIOD).format("MMM YYYY")
                          ), // Convert to milliseconds
                          title: {
                            text: "DATE", // X-axis label
                          },
                        },
                        yaxis: [
                          {
                            title: {
                              text: "PURCHASES", // Y-axis label
                            },
                          },
                        ],
                        title: {
                          text: "Scatter Chart",
                        },
                      }}
                      series={[
                        {
                          name: "Amount",
                          data: filteredData.map((item) => ({
                            x: moment(item?.PERIOD).format("MMM YYYY"), // Convert to milliseconds
                            y: item?.PURCHASES,
                          })),
                        },
                      ]}
                      type="scatter" // Set chart type to "scatter"
                      height={300}
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    marginTop: "50px",
                    textDecoration: "underline",
                  }}
                >
                  {selvehicleNo && <p> No Data Available</p>}
                  {!selvehicleNo && <p> Select Supplier Name</p>}
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default PurchasesSelectedSupplierPeriodWise;
