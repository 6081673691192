import React, { useRef } from "react";
import "./CSS/Login.css";

import imagelogin from "./Components/images/logindashboard.PNG";
import { CgPassword } from "react-icons/cg";
import { MdAlternateEmail } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import { FaEyeSlash } from "react-icons/fa";
import { login } from "./Components/features/userSlice";
import { useDispatch } from "react-redux";
import { selectUser } from "./Components/features/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingScreen from "./Components/LoadingScreen";
import Footer from "./Components/Footer";
import { setCompanyCode } from "./Components/features/userSlice";

const Login = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectUser);
  const [visible, setVisible] = useState(false);
  const values = {
    email: "",
    password: "",
  };
  const [logindetails, setLogindetails] = useState(values);
  const [errMsg, seterrMsg] = useState("");
  const errRef = useRef();
  const [isloading, setisloading] = useState(false);

  // useEffect(() => {
  //   if (isLoggedIn.loggedIn === true) {
  //     navigate("/HomePage");
  //   }
  // }, []);

  function handleInputs(e) {
    const { name, value } = e.target;
    setLogindetails({ ...logindetails, [name]: value });
  }

  function loginUser(e) {
    setisloading(true);
    e.preventDefault();

    axios
      .post(`${BASEURL}/userlogin`, {
        userDetails: logindetails,
      })
      .then(async (response) => {
        console.log(response.data);
        if (response.data.message) {
          seterrMsg(response.data.message);
          setisloading(false);
        } else {
          dispatch(
            login({
              email: response.data[0].EMAIL,
              usercode: response.data[0].USERCODE,
              loggedIn: true,
            })
          );
          window.sessionStorage.setItem(
            "auth-token",
            response.data[0].authToken
          );
          window.sessionStorage.setItem(
            "session-token",
            response.data[0].sclientSecret
          );
          await axios
            .get(`${BASEURL}/getCompanyDetails`, {
              headers: {
                "auth-token": response.data[0].authToken,
                "session-token": response.data[0].sclientSecret,
              },
            })
            .then((response) => {
              dispatch(
                setCompanyCode({
                  companyCode: response?.data[0]?.ORGCODE,
                })
              );
              console.log("Updated company code:", response.data);
            })
            .catch((error) => {
              console.error("Error fetching company details:", error);
            });
          navigate("/Dashboards");
        }
      })
      .catch((err) => {
        console.log(
          "Error While Logging in to Approval Platform :/userlogin",
          err
        );
      });
  }
  if (isloading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div className="container">
        <div className="login__img">
          <h1 className="Online_App"> MarsTrack Dashboards </h1>
          <img src={imagelogin} alt="" width={400} height={400} />
        </div>{" "}
        <div className="Login_form">
          <div className="login">
            <h1>Login</h1>
          </div>
          <div>
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
          </div>
          <form
            onSubmit={(e) => {
              loginUser(e);
            }}
          >
            <div className="Form">
              <div className="label_1">
                <label>Email</label>
              </div>
              <div className="wrapper">
                <div className="icon">
                  <MdAlternateEmail />
                </div>
                <input
                  className="inputBox"
                  type="email"
                  name="email"
                  placeholder="Enter your Email"
                  required
                  onChange={(e) => handleInputs(e)}
                ></input>
              </div>
              <div className="label_2">
                <label>Password</label>
              </div>
              <div className="wrapper">
                <div className="icon">
                  <CgPassword />
                </div>
                <input
                  className="inputBox"
                  type={visible ? "text" : "password"}
                  name="password"
                  placeholder="Enter your Password"
                  onChange={(e) => handleInputs(e)}
                ></input>
                <div className="eye">
                  {" "}
                  {visible && (
                    <AiOutlineEye onClick={() => setVisible(!visible)} />
                  )}
                  {!visible && (
                    <FaEyeSlash onClick={() => setVisible(!visible)} />
                  )}
                </div>
              </div>
              <span className="fgpass">
                <Link
                  to="/Forgotpwd "
                  className="click"
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  Forgot Password?
                </Link>
              </span>
              <button type="submit" className="lgn_button">
                Login
              </button>
            </div>
          </form>
        </div>{" "}
      </div>
    </>
  );
};

export default Login;
