import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "./Components/features/userSlice";
import { useDispatch } from "react-redux";
import Footer from "./Components/Footer";
import sales from "./Images/sales.PNG";
import stock from "./Images/stock.PNG";
import last_anaysis from "./Images/last_anaysis.PNG";
import sales_anaysis from "./Images/sales_anaysis.PNG";
import workshop from "./Images/workshop.PNG";
import costing from "./Images/costing.PNG";
import AllPagesMasterNavbar from "../src/Components/MasterNavbar/AllPagesMasterNavbar";

import { useSelector } from "react-redux";
import { selectUser } from "./Components/features/userSlice";
function AnalyticsDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userEmail = user.email;
  const logoutOff = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
  };

  return (
    <div>
      <AllPagesMasterNavbar />
      <div className="h2">
        <h1>Trading Analytics Dashboards</h1>
      </div>

      {/*  */}

      <section className="flex gap-5  flex-wrap lg:px-20 mt-5 justify-around">
        <div
          onClick={() => {
            navigate("/SalesDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={sales} height="150px" width="200px" alt="dashboard" />
          <h3>Sales Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/StockDashboard");
          }}
          className="align__img__and__heading"
        >
          <img src={stock} height="150px" width="200px" alt="dashboard" />
          <h3>Stock Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/PurchasesDashboard");
          }}
          className="align__img__and__heading"
        >
          <img
            src={sales_anaysis}
            height="150px"
            width="200px"
            alt="dashboard"
          />
          <h3>Purchases Analytics</h3>
        </div>
        <div
          onClick={() => {
            navigate("/FinancialTradingDashboard");
          }}
          className="align__img__and__heading"
        >
          <img
            src={last_anaysis}
            height="150px"
            width="200px"
            alt="dashboard"
          />
          <h3>Financial Analytics</h3>
        </div>

        <div
          onClick={() => {
            navigate("/ProductionCost");
          }}
          className="align__img__and__heading"
        >
          <img src={workshop} height="150px" width="180px" alt="dashboard" />
          <h3>Production Cost</h3>
        </div>
        <div
          onClick={() => {
            navigate("/ProductionQuantities");
          }}
          className="align__img__and__heading"
        >
          <img src={costing} height="150px" width="180px" alt="dashboard" />
          <h3>Production Quantities </h3>
        </div>
      </section>

      {/*
       */}
      {/* <div className="dashboard">
        <div className="first_line">
          <div
            className="card"
            onClick={() => {
              navigate("/SalesDashboard");
            }}
          >
            <div className="card-content">
              <div className="card-title">Sales Analytics</div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-title">Stock Analytics</div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-title">Purchases Analytics</div>
            </div>
          </div>

          <div className="card">
            <div className="card-content">
              <div className="card-title">Financial Analytics</div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default AnalyticsDashboard;
